import Toast from '../components/Common/Toaster/Toaster';
export const FETCH_DATA_MANAGEMENT = 'FETCH_DATA_MANAGEMENT';
export const UPDATE_DATA_MANAGEMENT = 'FETCH_DATA_MANAGEMENT';
export const FETCH_SELECTED_DROPDOWN = 'FETCH_SELECTED_DROPDOWN';
export const FETCH_SELECTED_DATA = 'FETCH_SELECTED_DATA';

const initialState = {
  allDatas: [],
  selectedDropdown: [],
  selectedData: [],
};

const DataManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_MANAGEMENT:
      return {
        ...state,
        allDatas: action.payload,
      };
    case FETCH_SELECTED_DROPDOWN:
      return {
        ...state,
        selectedDropdown: action.payload,
      };
    case FETCH_SELECTED_DATA:
      return {
        ...state,
        selectedData: action.payload,
      };

    default:
      return state;
  }
};
export default DataManagementReducer;

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_MANAGEMENT,
  payload: data,
});
export const fetchedDropdown = (data) => ({
  type: FETCH_SELECTED_DROPDOWN,
  payload: data,
});
export const fetchedSelectedData = (data) => ({
  type: FETCH_SELECTED_DATA,
  payload: data,
});

export const FetchDropdowns = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/get`,
        {
          method: 'GET',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const val = await response.json();
      dispatch(fetchDataSuccess(val));
      return val;
    } catch (error) {}
  };
};
export const FetchWoundTypeData = (Id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/get/${Id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const val = await response.json();
      dispatch(fetchedDropdown(val));
      return val;
    } catch (error) {}
  };
};

export const FetchSelectedData = (dropdownId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/get/${dropdownId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const val = await response.json();
      dispatch(fetchedSelectedData(val));
      return val;
    } catch (error) {}
  };
};

export const createDropdown = (createData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/create`,
        {
          method: 'POST',
          body: JSON.stringify({
            pageName: createData.pageName,
            dropdownName: [{ name: createData.name, _id: createData.id }],
          }),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      Toast.success('Added Successfully');
      return val;
    } catch (error) {}
  };
};

export const addDropdownValue = (addData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/create/add`,
        {
          method: 'POST',
          body: JSON.stringify(addData),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      Toast.success('Added Successfully');
      return val;
    } catch (error) {}
  };
};
export const editDropdownValue = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/edit/${data.dropdownId}/${data.itemId}`,
        {
          method: 'POST',
          body: JSON.stringify({ name: data.value }),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      //  dispatch(fetchDataSuccess(val));
      Toast.success('Edited Successfully');
      return val;
    } catch (error) {}
  };
};

export const deleteDropdownValue = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/delete/${data.dropdownId}/${data.itemId}`,
        {
          method: 'POST',
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      Toast.success('Deleted Successfully');
      return val;
    } catch (error) {}
  };
};
