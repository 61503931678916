import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditDeleteDescriptionData,
  FetchDescriptionCategoryData,
  FetchOneDescriptionCategoryData,
} from '../../Redux/DescriptionManagementRedux';
import { DeleteButton, EditButton, SaveButton } from '../Common/Tools/Buttons';
import AlertBox from '../Common/AlertBox/AlertBox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DescriptionDetails({ open, handleClose }) {
  const dispatch = useDispatch();
  const { oneCategoryData } = useSelector(
    (state) => state.DescriptionManagement
  );
  const [descriptionText, setDescriptionText] = useState([]);
  const [edit, setEdit] = useState(false);
  const [descriptionId, setDescriptionId] = useState('');
  const [saveOpen, setSaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (oneCategoryData && oneCategoryData.length > 0) {
      const descriptions = oneCategoryData.map((data) =>
        data.descriptionText.map((textObj) => textObj.text)
      );
      const flattenedDescriptions = descriptions.flat();
      const descriptionString = flattenedDescriptions.join('\n');
      setDescriptionText(descriptionString);
      const data = oneCategoryData[0]._id;
      setDescriptionId(data);
    }
  }, [dispatch, oneCategoryData]);

  const handleEditSave = (edit) => {
    if (edit === 'Edit') {
      setEdit(true);
    } else {
      setSaveOpen(true);
    }
  };
  const handleSaveConform = () => {
    const lines = descriptionText
      .split('\n')
      .filter((line) => line.trim() !== '');
    const descriptionTextArray = lines.map((text) => ({
      text: text.trim(),
    }));
    let editedData = {
      action: 'edit',
      id: descriptionId,
      descriptionText: descriptionTextArray,
    };
    dispatch(EditDeleteDescriptionData(editedData));
    dispatch(FetchOneDescriptionCategoryData(editedData.id));
    setEdit((prevEdit) => !prevEdit);
    setSaveOpen(false);
  };

  const handleDelete = () => {
    setDeleteOpen(true);
  };
  const handleDeleteConform = () => {
    let deleteData = {
      action: 'delete',
      id: descriptionId,
    };
    dispatch(EditDeleteDescriptionData(deleteData));
    let id = localStorage.getItem('selectedDescptId');
    dispatch(FetchDescriptionCategoryData(id));
    setDeleteOpen(false);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <div className="container">
          <div className="row">
            {oneCategoryData &&
              oneCategoryData.map((item) =>
                item.cardData.map((card) => (
                  <div className="col-4 d-flex p-4" key={card._id}>
                    <Card
                      className="d-flex flex-column h-100 shadow border border-1 w-100"
                      style={{
                        borderTopRightRadius: '4rem',
                        borderBottomLeftRadius: '4rem',
                        backgroundImage:
                          'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
                      }}
                    >
                      <CardHeader
                        className="text-center pb-1"
                        title={
                          <>
                            <h5 className="fw-semibold">{card.dropdownName}</h5>
                            <p>({card.category})</p>
                            <div
                              className="my-1"
                              style={{
                                borderImage:
                                  'linear-gradient(to right, #0acffe 0%, #495aff 100%) 1',
                                borderBottom: '2px solid',
                                borderWidth: '0 0 2px 0',
                                width: '50%',
                                margin: 'auto',
                              }}
                            ></div>
                          </>
                        }
                      ></CardHeader>
                      <CardContent className="flex-grow-1 text-center pb-1">
                        {card.value.map((value) => (
                          <Typography
                            sx={{ color: '#030637' }}
                            className="fw-medium p-1"
                            key={value._id}
                            variant="body2"
                          >
                            <RadioButtonCheckedIcon /> {value.name}
                          </Typography>
                        ))}
                      </CardContent>
                    </Card>
                  </div>
                ))
              )}
          </div>
          <div className="col-md-12 my-2">
            <div
              className="card shadow border border-1 position-relative  h-100"
              style={{
                borderTopRightRadius: '4rem',
                borderBottomLeftRadius: '4rem',
                backgroundImage:
                  'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
              }}
            >
              <div className="card-body">
                <div className="mb-2 pb-2 border-bottom">
                  <div className="text-center">
                    <h5 className="text-uppercase fw-weight-bold text-center">
                      Description
                    </h5>
                  </div>
                </div>
                <div className="row my-1 d-flex align-items-center">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1"></label>
                    <textarea
                      className="form-control"
                      id="descriptionTextArea"
                      rows="10"
                      disabled={!edit}
                      value={descriptionText}
                      onChange={(e) => setDescriptionText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="row p-3 d-flex justify-content-evenly">
                    <div className="col-md-3">
                      {edit ? (
                        <SaveButton
                          onClick={() => handleEditSave(edit ? 'Save' : 'Edit')}
                        >
                          Save Description
                        </SaveButton>
                      ) : (
                        <EditButton
                          onClick={() => handleEditSave(edit ? 'Save' : 'Edit')}
                        >
                          Edit Description
                        </EditButton>
                      )}
                    </div>
                    <div className="col-md-3">
                      <DeleteButton onClick={() => handleDelete()}>
                        Delete Description
                      </DeleteButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <AlertBox
        open={saveOpen || deleteOpen}
        handleClose={() => {
          setSaveOpen(false);
          setDeleteOpen(false);
        }}
        action={saveOpen ? 'saveDescription' : deleteOpen ? 'delete' : ''}
        deleteSubmit={handleDeleteConform}
        saveSubmit={handleSaveConform}
      ></AlertBox>
    </React.Fragment>
  );
}
