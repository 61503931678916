import React from 'react';
import Modal from '@mui/material/Modal';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { useSelector } from 'react-redux';

function Profile({ open, handleCloseProfile }) {
  const { userDetail } = useSelector((state) => state.staffDetails);
  const user = userDetail && userDetail[0];
  const handleClose = () => {
    handleCloseProfile();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="user_card bg-light">
        {user && (
          <div className="row mx-0">
            <div className="col-md-4 col-sm-12">
              <div className=" text-center text-black userProfile">
                <div className="userProfileText">
                  <image
                    src="https://img.icons8.com/bubbles/100/000000/user.png"
                    className="profile-image"
                    alt="User-Profile-Image"
                    style={{}}
                  />
                </div>
                <h4>{user.userName}</h4>
                <h6>({user.role})</h6>
                {/* <p>
                  RamTech IT Solutions
                  <br />
                  srirangam
                  <br />
                  Trichy
                </p> */}
              </div>
            </div>
            <div className=" col-md-8 col-sm-12">
              <div className="userDetails">
                <h6
                  className="infoText"
                  style={{
                    marginBottom: '20px',
                    paddingBottom: '5px',
                    borderBottom: '1px solid #000',
                    fontWeight: '600',
                    textAlign: 'center',
                  }}
                >
                  Information
                </h6>
                <div className="row">
                  <h6
                    style={{
                      marginBottom: '10px',
                      // paddingBottom: '5px',
                      fontWeight: '600',
                    }}
                  >
                    Contact
                  </h6>
                  <p style={{ marginBottom: '10px', fontWeight: '600' }}>
                    <EmailIcon className="me-5" style={{ fontSize: '2rem' }} />
                    <span
                      style={{
                        color: '#6c757d',
                        fontWeight: '400',
                        fontSize: '1.15rem',
                      }}
                    >
                      {user.emailId}
                    </span>
                  </p>
                  <p
                    style={{
                      marginBottom: '10px',
                      marginTop: '10px',
                      fontWeight: '600',
                    }}
                  >
                    <CallIcon className="me-5" style={{ fontSize: '2rem' }} />
                    <span
                      style={{
                        color: '#6c757d',
                        fontWeight: '400',
                        fontSize: '1.25rem',
                      }}
                    >
                      {user.phoneNumber}
                    </span>
                  </p>
                </div>
                <h6
                  style={{
                    marginTop: '15px',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                    fontWeight: '600',
                  }}
                >
                  Address
                </h6>
                <p
                  style={{
                    marginLeft: '20%',
                    color: '#6c757d',
                    fontWeight: '400',
                    fontSize: '1.25rem',
                  }}
                >
                  {user.address}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default Profile;
