import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionDetails from './DescriptionDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  FetchDescriptionCategoryData,
  FetchOneDescriptionCategoryData,
} from '../../Redux/DescriptionManagementRedux';

const DescriptionCard = ({ selectedValue }) => {
  const dispatch = useDispatch();
  const { categoryData } = useSelector((state) => state.DescriptionManagement);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let id = localStorage.getItem('selectedDescptId');
    dispatch(FetchDescriptionCategoryData(id));
  }, [dispatch]);

  const handleOpenDialog = (itemId) => {
    let id = itemId;
    dispatch(FetchOneDescriptionCategoryData(id));
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    let id = localStorage.getItem('selectedDescptId');
    dispatch(FetchDescriptionCategoryData(id));
    setOpenDialog(false);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {categoryData &&
            categoryData.map((item) => (
              <div className="col-4 d-flex p-2" key={item._id}>
                <Card
                  className="d-flex flex-column h-100 shadow border border-1"
                  style={{
                    borderTopRightRadius: '4rem',
                    borderBottomLeftRadius: '4rem',
                    backgroundImage:
                      'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
                  }}
                >
                  <CardHeader
                    className="text-center pb-1"
                    title={
                      <>
                        <h5 className="fw-semibold">
                          {item.woundSubCategory.name}
                        </h5>
                        <div
                          style={{
                            borderImage:
                              'linear-gradient(to right, #0acffe 0%, #495aff 100%) 1',
                            borderBottom: '2px solid',
                            borderWidth: '0 0 2px 0',
                            width: '70%',
                            margin: 'auto',
                          }}
                        ></div>
                      </>
                    }
                  ></CardHeader>
                  <CardContent className="flex-grow-1">
                    {item.descriptionText.map((description) => (
                      <Typography
                        sx={{ color: '#030637' }}
                        className="fw-medium p-1"
                        key={description._id}
                        variant="body2"
                      >
                        {description.text}
                      </Typography>
                    ))}
                  </CardContent>

                  <CardActions className="d-flex ">
                    <Button
                      onClick={() => handleOpenDialog(item._id)}
                      className="ms-auto"
                      variant="text"
                      startIcon={<VisibilityIcon />}
                    >
                      <p>View</p>
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
          {!categoryData && (
            <>
              {' '}
              <h6>Description Not Found !!!</h6>
            </>
          )}
        </div>
      </div>
      <DescriptionDetails open={openDialog} handleClose={handleCloseDialog} />
    </>
  );
};

export default DescriptionCard;
