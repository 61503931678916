import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setViewDetails } from '../../Redux/PatientRedux';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PatientDetails() {
  const dispatch = useDispatch();
  const pdfBlob = useSelector((state) => state.PatientDetails.pdfBlob); // Ensure your Redux store holds the PDF Blob
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);
      return () => URL.revokeObjectURL(url); // Clean up the URL object when the component unmounts
    }
  }, [pdfBlob]);

  const handleTrue = () => {
    dispatch(setViewDetails(false));
  };

  return (
    <div className='container'>
      <IconButton onClick={handleTrue}>
        <ArrowBackIcon />
      </IconButton>
      {pdfUrl && (
        <div>
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            <Viewer fileUrl={pdfUrl} />
          </Worker>
        </div>
      )}
    </div>
  );
}

export default PatientDetails;
