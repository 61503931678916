import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LoginIcon from '@mui/icons-material/Login';

export const LoginButton = ({ onClick, children, type }) => {
  return (
    <Button
      fullWidth
      startIcon={<LoginIcon />}
      variant="contained"
      onClick={onClick}
      type={type}
      style={{
        backgroundImage:
          'radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% )',
      }}
    >
      {children}
    </Button>
  );
};

export const CreateButton = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onClick}
      style={{
        backgroundImage: 'linear-gradient(to right, #0acffe 0%, #495aff 100%)',
      }}
    >
      {children}
    </Button>
  );
};

export const ViewButton = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      startIcon={<VisibilityIcon />}
      onClick={onClick}
      style={{
        backgroundImage: 'linear-gradient(to right, #0acffe 0%, #495aff 100%)',
      }}
    >
      {children}
    </Button>
  );
};

export const NewaddButton = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      type="submit"
      endIcon={<LibraryAddIcon />}
      onClick={onClick}
      style={{
        backgroundImage:
          'linear-gradient( 86.3deg,  rgba(0,119,182,1) 3.6%, rgba(8,24,68,1) 87.6% )',
      }}
    >
      {children}
    </Button>
  );
};

export const CancelButton = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      endIcon={<CancelIcon />}
      onClick={onClick}
      style={{
        backgroundImage:
          'radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgba(226,24,24,1) 0%, rgba(160,6,6,1) 90% )',
      }}
    >
      {children}
    </Button>
  );
};

export const DeleteButton = ({ onClick, children }) => {
  return (
    <Button
      endIcon={<DeleteIcon />}
      variant="contained"
      onClick={onClick}
      style={{
        backgroundImage:
          ' radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgba(226,24,24,1) 0%, rgba(160,6,6,1) 90% )',
      }}
    >
      {children}
    </Button>
  );
};

export const AddButton = ({ onClick, children }) => {
  return (
    <Button
      endIcon={<AddCircleIcon />}
      variant="contained"
      onClick={onClick}
      style={{
        backgroundImage: 'linear-gradient(to top, #4481eb 0%, #04befe 100%)',
      }}
    >
      {children}
    </Button>
  );
};

export const EditButton = ({ onClick, children }) => {
  return (
    <Button
      endIcon={<EditIcon />}
      variant="contained"
      onClick={onClick}
      style={{
        backgroundImage:
          'linear-gradient( 86.3deg,  rgba(0,119,182,1) 3.6%, rgba(8,24,68,1) 87.6% )',
      }}
    >
      {children}
    </Button>
  );
};

export const YesButton = ({ onClick, children }) => {
  return (
    <Button
      endIcon={<CheckCircleRoundedIcon />}
      variant="contained"
      onClick={onClick}
      style={
        {
          // backgroundImage: 'linear-gradient(to top, #9be15d 0%, #00e3ae 100%)',
        }
      }
    >
      {children}
    </Button>
  );
};

export const SaveButton = ({ onClick, children, disabled }) => {
  return (
    <Button
      variant="contained"
      endIcon={<SaveIcon />}
      onClick={onClick}
      disabled={disabled}
      style={{
        backgroundImage:
          'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
        background: disabled
          ? '#999'
          : 'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
      }}
    >
      {children}
    </Button>
  );
};

export const SubmitButton = ({ onClick, children, type }) => {
  return (
    <Button
      variant='contained'
      onClick={onClick}
      type={'submit'}
      fullWidth
      style={{
        backgroundImage:
          'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
      }}
    >
      {children}
    </Button>
  );
};


