/* eslint-disable no-unused-vars */
import Toast from '../components/Common/Toaster/Toaster';
export const ALL_TREATMENT_CATEGORY = 'ALL_TREATMENT_CATEGORY';
export const ALL_TREATMENT_SUGGESTION = 'ALL_TREATMENT_SUGGESTION';





const initialState = {
    allTreatment: [],
    allTreatmentSuggestion:[],
  };

  const TreatmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case ALL_TREATMENT_CATEGORY:
        return {
          ...state,
          allTreatment: action.payload,
        };
        case ALL_TREATMENT_SUGGESTION:
          return {
            ...state,
            allTreatmentSuggestion: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default TreatmentReducer;
  

  export const fetchAllTreatment = (treatment) => ({
    type: ALL_TREATMENT_CATEGORY,
    payload: treatment,
  });

  export const fetchAllTreatmentSuggestion = (treatment) => ({
    type: ALL_TREATMENT_SUGGESTION,
    payload: treatment,
  });



  export const getAllTreatment =  () => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_api_url}/api/get/treatment/category`,
          {
            method: 'GET',
          }
        );
        if (!response.ok) {
          throw new Error('Failed to get treatment');
        }
        const val = await response.json();
        dispatch(fetchAllTreatment(val));
      } catch (error) {}
    };
  };


  export const getAllTreatmentSuggestion = (queryParams) => {
    return async (dispatch) => {
      try {
        const queryString = new URLSearchParams(queryParams).toString();
        const response = await fetch(
          `${process.env.REACT_APP_api_url}/api/getall/treatment?${queryString}`,
          {
            method: 'GET',
          }
        );

        if (!response.ok) {
          throw new Error('Failed to get treatment');
        }

        const val = await response.json();
        dispatch(fetchAllTreatmentSuggestion(val));
      } catch (error) {
        console.error(error);
      }
    };
  };

  export const actionTreatmentCategory = (action, data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_api_url}/api/treatment/${action}`,
          {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (!response.ok) {
          Toast.error('Internal Server Error');
          throw new Error('Failed');
        }

        const val = await response.json();
        dispatch(getAllTreatment());
        if (action === 'edit') {
          Toast.success('Edited Successfully');
        } else {
          Toast.success('Added Successfully');
        }
        return val;
      } catch (error) {
        console.error(error);
      }
    };
  };

  export const createTreatments = (data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_api_url}/api/create/treatment`,
          {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (!response.ok) {
          Toast.error('Internal Server Error');
          throw new Error('Failed');
        }

        const val = await response.json();
        Toast.success('Added Successfully');
        return val;
      } catch (error) {
        console.error(error);
      }
    };
  };

  export const editTreatments = (summaryId, data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_api_url}/api/edit/treatment/${summaryId}`,
          {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (!response.ok) {
          Toast.error('Internal Server Error');
          throw new Error('Failed');
        }

        const val = await response.json();
        Toast.success('Edited Successfully');
        return val;
      } catch (error) {
        console.error(error);
      }
    };
  };


