import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import { SubmitButton } from '../Common/Tools/Buttons';
import {
  createHouseProduct,
  dialogOpen,
  setEdit,
  editHouseProduct,
} from '../../Redux/ProductRedux';

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 90;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(item, selectedTreatments, theme) {
  const isSelected = selectedTreatments.some(
    (treatment) => treatment._id === item._id
  );
  return {
    fontWeight: isSelected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
    background: isSelected
      ? 'linear-gradient(89.5deg, rgba(131,204,255,1) 0.4%, rgba(66,144,251,1) 100.3%)'
      : 'transparent',
    color: isSelected ? '#000 ' : 'inherit',
  };
}

export default function AddHouseProduct() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { allTreatment } = useSelector((state) => state.TreatmentManagement);
  const { allHouseProducts, setEditId } = useSelector(
    (state) => state.ProductManagements
  );

  const [treatmentValues, setTreatmentValues] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [productType, setProductType] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [description, setDescription] = useState('');
  const [packSize, setPackSize] = useState('');
  const [code, setCode] = useState('');
  const [suitableForType, setSuitableForType] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [levelOfAdoption, setLevelOfAdoption] = useState('');
  const [manufacturersRecommendation, setManufacturersRecommendation] =
    useState('');
  const [price, setPrice] = useState('');
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    if (allTreatment && allTreatment.length > 0) {
      const values = allTreatment.flatMap((category) =>
        category.categoryValue.map((item) => ({
          _id: item._id,
          value: item.value,
        }))
      );
      setTreatmentValues(values);
    }
  }, [allTreatment]);

  useEffect(() => {
    if (setEditId !== 0 && Object.keys(setEditId).length !== 0) {
      const filteredProduct = allHouseProducts.find(
        (product) => product._id === setEditId
      );
      setEditValue(filteredProduct);
    }
  }, [setEditId]);

  useEffect(() => {
    if (Object.keys(editValue).length !== 0) {
      setProductType(editValue.productType || '');
      setManufacturer(editValue.manufacturer || '');
      setDescription(editValue.description || '');
      setPackSize(editValue.packSize || '');
      setCode(editValue.code || '');
      setQuantity(editValue.quantity || '');
      setLevelOfAdoption(parseInt(editValue.levelOfAdoption) || '');
      setManufacturersRecommendation(
        editValue.manufacturersRecommendation || ''
      );
      setPrice(editValue.price || '');
      const suitableForTypeFormatted = editValue.suitableForType.map((item) =>
        parseInt(item.value, 10)
      );
      setSuitableForType(suitableForTypeFormatted);
      setSelectedTreatments(editValue.treatmentFor);
    }
  }, [editValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    const lastValue = value[value.length - 1];
    console.log('Selected value:', lastValue);

    if (
      selectedTreatments.some((treatment) => treatment._id === lastValue._id)
    ) {
      setSelectedTreatments((prev) =>
        prev.filter((treatment) => treatment._id !== lastValue._id)
      );
    } else {
      setSelectedTreatments((prev) => [...prev, lastValue]);
    }
  };

  // const handleChange = (event) => {
  //   const { value } = event.target; 
  //   const lastValue = value[value.length - 1]; 
  //   console.log("value",value);

  //   console.log('Selected value:', lastValue);

  //   // Determine if the last action was an add or remove
  //   const isItemAdded = value.length > selectedTreatments.length;

  //   if (isItemAdded) {
  //     setSelectedTreatments((prev) => {
  //       const newTreatments = [...prev, lastValue];
  //       console.log('selectedTreatments add:', newTreatments);
  //       return newTreatments;
  //     });
  //   } else {
  //     setSelectedTreatments((prev) => {
  //       const newTreatments = prev.filter((treatment) => treatment._id !== lastValue._id);
  //       console.log('selectedTreatments removed:', newTreatments);
  //       return newTreatments;
  //     });
  //   }
  // };


  // const handleChange = (event) => {
  //   console.log("event",event);
  //     const {
  //       target: { value },
  //     } = event;
  //     setSelectedTreatments(value);
  //   }
     

  useEffect(() => {
    console.log("selectedTreatments updated:", selectedTreatments);
  }, [selectedTreatments]);
  

  const handleSuitableClick = (numbers) => {
    if (suitableForType.includes(numbers)) {
      setSuitableForType(suitableForType.filter((num) => num !== numbers));
    } else {
      setSuitableForType([...suitableForType, numbers]);
    }
  };

  const handleAdoption = (number) => {
    if (levelOfAdoption === number) {
      setLevelOfAdoption('');
    } else {
      setLevelOfAdoption(number);
    }
  };

  const handleDelete = (itemToDelete) => () => {
    setSelectedTreatments((prev) =>
      prev.filter((item) => item._id !== itemToDelete._id)
    );
  };
  const handleSave = () => {
    const suitableForTypeFormatted = suitableForType.map((value) => ({
      value: value.toString(),
    }));
    // const treatmentForFormatted = selectedTreatments.map((value) => ({
    //   value,
    // }));
    const data = {
      productType: productType,
      manufacturer: manufacturer,
      description: description,
      packSize: packSize,
      code: code,
      manufacturersRecommendation: manufacturersRecommendation,
      levelOfAdoption: levelOfAdoption,
      quantity: quantity,
      price: price,
      suitableForType: suitableForTypeFormatted,
      treatmentFor: selectedTreatments,
    };

    if (setEditId !== 0 && Object.keys(setEditId).length !== 0) {
      const id = setEditId;
      dispatch(editHouseProduct(data, id));
      dispatch(setEdit(0));
      dispatch(dialogOpen(false));
    } else {
      dispatch(createHouseProduct(data));
      dispatch(dialogOpen(false));
    }
  };

  return (
    <div className='container-fluid'>
      <div className='col-md-12 p-2 text-center'>
        <h4
          style={{
            backgroundColor: '#002366',
            color: '#fff',
            padding: '10px',
          }}>
          Add Products In House
        </h4>
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Product Type'
          variant='standard'
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
          required
        />
        <TextField
          className='col-md-5'
          label='Manufacturer'
          variant='standard'
          value={manufacturer}
          onChange={(e) => setManufacturer(e.target.value)}
          required
        />
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Description'
          variant='standard'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <TextField
          className='col-md-5'
          label='Pack Size'
          variant='standard'
          value={packSize}
          inputProps={{ maxLength: 10 }}
          onChange={(e) => setPackSize(e.target.value)}
          required
        />
      </div>

      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Code'
          variant='standard'
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
        <TextField
          className='col-md-5'
          label='Quantity'
          variant='standard'
          value={quantity}
          placeholder='Numbers Only'
          onChange={(e) => setQuantity(e.target.value)}
          required
        />
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <TextField
          className='col-md-5'
          label='Manufacturers Recommendation In Brief'
          variant='standard'
          value={manufacturersRecommendation}
          onChange={(e) => setManufacturersRecommendation(e.target.value)}
          required
        />
        <TextField
          className='col-md-5'
          label='Price(S$)'
          variant='standard'
          value={price}
          placeholder='Singapore Dollar'
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
      <div className='row d-flex justify-content-around mt-3'>
        <div className='number-buttons col-md-5'>
          <p className='row fs-6'>Suitable For Type(1-8)*</p>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((numbers) => (
            <Button
              className='row'
              key={numbers}
              variant='outlined'
              onClick={() => handleSuitableClick(numbers)}
              sx={{
                fontSize: '15px',
                marginRight: '10px',
                marginBottom: '10px',
                backgroundColor: suitableForType.includes(numbers)
                  ? '#f0f0f0'
                  : 'transparent',
                color: suitableForType.includes(numbers) ? '#000' : '',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}>
              {numbers}
            </Button>
          ))}
        </div>
        <div className='number-buttons col-md-5'>
          <p className='row fs-6 '>Level Of Adoption Scale(1-5)*</p>
          {[1, 2, 3, 4, 5].map((number) => (
            <Button
              className='row'
              key={number}
              variant='outlined'
              onClick={() => handleAdoption(number)}
              sx={{
                fontSize: '15px',
                marginRight: '10px',
                marginBottom: '10px',
                backgroundColor:
                  levelOfAdoption === number ? '#f0f0f0' : 'transparent',
                color: levelOfAdoption === number ? '#000' : '',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}>
              {number}
            </Button>
          ))}
        </div>
      </div>
      <div className='row d-flex justify-content-around mt-2'>
        <FormControl
          className='col-md-11'
          sx={{ m: 1 }}>
          <InputLabel id='demo-multiple-chip-label'>Treatments</InputLabel>
          <Select
            labelId='demo-multiple-chip-label'
            id='demo-multiple-chip'
            multiple
            required
            value={selectedTreatments}
            onChange={handleChange}
            input={
              <OutlinedInput
                id='select-multiple-chip'
                label='Treatment Values'
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selected.map((item) => (
                  <Chip
                    key={item._id}
                    label={item.value}
                    onDelete={handleDelete(item)}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    }
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}>
            {treatmentValues.map((item) => (
              <MenuItem
                key={item._id}
                value={item}
                style={getStyles(item, selectedTreatments, theme)}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className='row d-flex mt-4 justify-content-center'>
        <SubmitButton onClick={handleSave}>Save</SubmitButton>
      </div>
    </div>
  );
}
