import { combineReducers } from 'redux';
import staffDetails from '../StaffRedux';
import PatientDetails from '../PatientRedux';
import Auth from '../LocalRedux';
import DropDowns from '../DataManagementRedux';
import AssesmentItems from '../AssesmentManagementRedux';
import TreatmentManagement from '../TreatmentRedux';
import DescriptionManagement from '../DescriptionManagementRedux';
import ProductManagements from '../ProductRedux';

const rootReducer = combineReducers({
  staffDetails: staffDetails,
  PatientDetails:PatientDetails,
  Auth: Auth,
  DropDowns: DropDowns,
  AssesmentItems: AssesmentItems,
  TreatmentManagement:TreatmentManagement,
  ProductManagements:ProductManagements,
  DescriptionManagement: DescriptionManagement,
});

export default rootReducer;
