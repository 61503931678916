/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import BadgeIcon from '@mui/icons-material/Badge';
import AddchartIcon from '@mui/icons-material/Addchart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MedicationIcon from '@mui/icons-material/Medication';
import HealingIcon from '@mui/icons-material/Healing';
import AirlineSeatLegroomExtraIcon from '@mui/icons-material/AirlineSeatLegroomExtra';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FeedIcon from '@mui/icons-material/Feed';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Link } from 'react-router-dom';
import HeaderNavar from '../Header/HeaderNavbar';
import IconButton from '@mui/material/IconButton';
import logoImage from '../../assets/images/KromhLogo.jpg';
import MenuIcon from '@mui/icons-material/Menu';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import { useDispatch, useSelector } from 'react-redux';
import {
  FetchWoundTypeData,
  FetchSelectedData,
  FetchDropdowns,
} from '../../Redux/DataManagementRedux';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 240;

const Sidebar = () => {
  const [showIconsOnly, setShowIconsOnly] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const dispatch = useDispatch();
  const { selectedDropdown, allDatas } = useSelector(
    (state) => state.DropDowns
  );

  useEffect(() => {
    dispatch(FetchDropdowns());
  }, []);

  const defaultIcon = <FiberNewIcon />;
  const subMenuIcon = {
    'Pressure Injury': <TireRepairIcon />,
    'Skin Tear': <HealingIcon />,
    'Lower Extremity Wound': <AirlineSeatLegroomExtraIcon />,
    Burns: <LocalFireDepartmentIcon />,
  };
  const sidebarData = [
    {
      name: 'Dashboard',
      title: 'Dashboard',
      link: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      name: 'StaffsInfo',
      title: 'Staffs Info',
      link: '/staffs',
      icon: <GroupsIcon />,
    },
    {
      name: 'PatientsInfo',
      title: 'Patients Info',
      link: '/patients',
      icon: <BadgeIcon />,
    },
    {
      name: 'DataManagement',
      title: 'Data Management',
      link: '/datamanagement',
      icon: <AddchartIcon />,
    },
    {
      name: 'AssesmentManagement',
      title: 'Assesment Management',
      link: '/assesment',
      icon: <AssessmentIcon />,
    },
    {
      name: 'TreatmentManagement',
      title: 'Treatment Management',
      icon: <MedicationIcon />,
      subItems: [
        {
          name: 'TreatmentCategory',
          title: 'Treatment Category',
          icon: <FormatListBulletedIcon />,
          link: '/treatmentcategory',
        },
        {
          name: 'TreatmentSuggestion',
          title: 'Treatment Suggestion',
          icon: <FeedIcon />,
          link: '/treatmentsuggestion',
        },
      ],
    },
    {
      name: 'ProductsManagement',
      title: 'Products Management',
      icon: <MedicalInformationIcon />,
      subItems: [
        {
          name: 'In House Products',
          title: 'In House Products',
          icon: <HomeIcon />,
          link: '/inhouseproducts',
        },
        {
          name: 'Market Products',
          title: 'Market Products',
          icon: <StoreIcon />,
          link: '/marketproducts',
        },
      ],
    },
  ];

  useEffect(() => {
    setShowIconsOnly(isMobileScreen);
  }, [isMobileScreen]);

  const handleToggleIconsOnly = () => {
    setShowIconsOnly(!showIconsOnly);
  };

  const handleDropdownToggle = (name) => () => {
    setOpenDropdown(openDropdown === name ? '' : name);
  };

  return (
    <>
      <HeaderNavar />
      <Drawer
        variant='permanent'
        sx={{
          width: showIconsOnly ? 72 : drawerWidth,
          flexShrink: 0,
          backgroundImage:
            'linear-gradient(to right, #0acffe 0%, #495aff 100%)',
          color: '#fff',
          bgcolor: 'background.default',
          transition: 'width .5s ease',
          [`& .MuiDrawer-paper`]: {
            width: showIconsOnly ? 72 : drawerWidth,
            boxSizing: 'border-box',
            // backgroundColor: '#223663',
            backgroundImage:
              'linear-gradient(to right, #0acffe 0%, #495aff 100%)',
            transition: 'width .5s ease',
          },
        }}>
        <Toolbar />
        <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
          {/* <div className='logo-img p-3'>
          <img src={logoImage} class="img-thumbnail" alt="logoImg"/>

          </div> */}
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleToggleIconsOnly}
            sx={{
              marginRight: 2,
              color: 'white',
              marginLeft: showIconsOnly ? 'auto' : '75%',
            }}>
            {showIconsOnly ? <MenuIcon /> : <ArrowCircleLeftRoundedIcon />}
          </IconButton>
          <List>
            {sidebarData.map((data, index) => (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{ width: showIconsOnly ? '100%' : 'auto' }}>
                  {data.subItems ? (
                    <ListItemButton
                      onClick={handleDropdownToggle(data.name)}
                      sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemIcon
                        className='fw-bolder'
                        sx={{ color: 'white', marginRight: 1 }}>
                        {data.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'white' }}
                        className='dSidebarTypo fw-bolder'
                        primary={data.title}
                      />
                      <ListItemIcon
                        className='fw-bolder'
                        sx={{ color: 'white', marginLeft: '60%' }}>
                        {openDropdown === data.name ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      component={Link}
                      sx={{ borderBottom: '1px solid #2596be' }}
                      to={data.link}>
                      <ListItemIcon
                        className='fw-bolder'
                        sx={{ color: 'white' }}>
                        {data.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'white' }}
                        className='dSidebarTypo fw-bolder'
                        primary={data.title}
                      />
                    </ListItemButton>
                  )}
                </ListItem>
                {data.subItems && (
                  <Collapse
                    in={openDropdown === data.name}
                    timeout='auto'
                    unmountOnExit>
                    <List
                      component='div'
                      disablePadding>
                      {data.subItems.map((subItem, subIndex) => (
                        <ListItem
                          key={subIndex}
                          disablePadding>
                          <ListItemButton
                            component={Link}
                            sx={{
                              borderBottom: '1px solid #2596be',
                              paddingLeft: showIconsOnly ? 2 : 4,
                            }}
                            to={subItem.link}>
                            <ListItemIcon sx={{ color: 'white' }}>
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              sx={{ color: 'white' }}
                              className='dSidebarTypo'
                              primary={subItem.title}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
