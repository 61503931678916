import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import { Logo } from '../../assets';
import './AvatarDropdown.css';

import { useEffect, useState } from 'react';
import AvatarDropdown from './AvatarDropdown';

export default function HeaderNavar() {
  const [avatar, setAvatar] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);
      const firstLetter = user.userName.charAt(0).toUpperCase();
      setAvatar(firstLetter);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsDropdownOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#ffffff',
      }}
    >
      <Toolbar>
        <Logo />
        <div style={{ flexGrow: 1, color: 'red' }} />
        <div
          className={`d-flex flex-row-reverse text-danger ${
            isDropdownOpen ? 'avatar-dropdown-open' : ''
          }`}
        >
          <Avatar
            alt={avatar}
            src="/static/images/avatar/2.jpg"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          />
          <AvatarDropdown anchorEl={anchorEl} handleClose={handleClose} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
