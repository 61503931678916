/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import StaffsTable from './StaffInfoTable';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { NewaddButton } from '../Common/Tools/Buttons';
import CloseIcon from '@mui/icons-material/Close';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from '../StyledComponents/StyledComponents';
import PageLayout from '../Layout/Layout';
import StaffRegistration from './StaffRegistration/StaffRegistration';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneStaff, fetchStaffs } from '../../Redux/StaffRedux';
import { localValue } from '../../Redux/LocalRedux';

const Staffs = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.Auth);

  const handleClose = () => {
    setOpen(false);
    dispatch(localValue(false));
    dispatch(localValue(false));
  };
  const AddStaffs = () => {
    setOpen(true);
    dispatch(localValue(true));
    dispatch(localValue(true));
  };

  useEffect(() => {
    dispatch(fetchStaffs());
  }, []);

  useEffect(() => {
    setOpen(value);
  }, [value]);

  const handleSearch = (event) => {
    dispatch(fetchOneStaff(event.target.value));
    if (event.target.value === '') {
      if (event.target.value === '') {
        dispatch(fetchStaffs());
      }
    }
  };
  return (
    <PageLayout>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='col-12 col-md-12 col-sm-12 d-md-block'>
          <div className='row py-3'>
            <p className='heading'>Staffs Info</p>
          </div>
          <div className='row '>
            <div className=' col-md-10 col-sm-12 d-flex flex-row'>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder='Staff Name / ID'
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleSearch}
                />
              </Search>
            </div>
            <div className='col-2 col-md-2 col-sm-12 d-md-block  align-self-end pb-2'>
              <NewaddButton type='submit' onClick={AddStaffs}>
                New staff
              </NewaddButton>
            </div>
          </div>
          <div className='row pt-5'>
            <StaffsTable />
          </div>
        </div>
        <Dialog open={Boolean(open)} onClose={handleClose}>
          <div>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ padding: '40px' }}>
            <StaffRegistration />
          </div>
        </Dialog>
      </Box>
    </PageLayout>
  );
};

export default Staffs;
