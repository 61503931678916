/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageLayout from '../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { FetchAssesmentItems } from '../../Redux/AssesmentManagementRedux';
import { Box, Button, TextField, Toolbar } from '@mui/material';
import { NewaddButton, SubmitButton } from '../Common/Tools/Buttons';
import {
  createTreatments,
  editTreatments,
  getAllTreatment,
  getAllTreatmentSuggestion,
} from '../../Redux/TreatmentRedux.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CloseIcon from '@mui/icons-material/Close';
import Toast from '../Common/Toaster/Toaster.js';
import EditNoteIcon from '@mui/icons-material/EditNote';

const TreatmentSuggestion = () => {
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();
  const { allAsesments } = useSelector((state) => state.AssesmentItems);
  const { allTreatmentSuggestion } = useSelector(
    (state) => state.TreatmentManagement
  );
  const { allTreatment } = useSelector((state) => state.TreatmentManagement);
  const [open, setOpen] = React.useState(false);
  const [treatmentValues, setTreatmentValues] = useState([]);
  const [activeTab, setActiveTab] = useState('Tissue Viability');
  const [isClearable, setIsClearable] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [summaryId, setSummaryId] = useState('');
  const [selectedValues, setSelectedValues] = useState({
    firstField: '',
    secondField: '',
    thirdField: '',
    fourthField: '',
    fifthField: '',
  });
  const [treatmentSuggestions, setTreatmentSuggestions] = useState([]);
  const [advancedTherapies, setAdvancedTherapies] = useState('');

  const activeTabData = allAsesments.find(
    (item) => item.dropdownName === activeTab
  );

  useEffect(() => {
    dispatch(getAllTreatmentSuggestion({ dropdownName: activeTab }));
  }, [activeTab]);

  useEffect(() => {
    dispatch(FetchAssesmentItems());
    dispatch(getAllTreatment());
  }, []);

  useEffect(() => {
    if (allTreatment && allTreatment.length > 0) {
      const values = allTreatment.flatMap((category) =>
        category.categoryValue.map((item) => ({
          _id: item._id,
          value: item.value,
        }))
      );
      setTreatmentValues(values);
    }
  }, [allTreatment]);

  const handleSelectChange = (field, selectedOptions) => {
    const selected = selectedOptions ? selectedOptions.value : null;
    setSelectedValues((prev) => ({ ...prev, [field]: selected }));
  };

  const handleTreatmentChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => ({
          value: option.value,
          label: option.label,
        }))
      : [];
    setTreatmentSuggestions(selectedValues);
  };

  const handleTextFieldChange = (field, value) => {
    setAdvancedTherapies(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedValues({
      firstField: '',
      secondField: '',
      thirdField: '',
      fourthField: '',
      fifthField: '',
    });
    setTreatmentSuggestions('');
    setAdvancedTherapies('');
    setSummaryId('');
    setIsEdit(false);
  };

  const addNewTreatment = () => {
    setIsEdit(false);
    setOpen(true);
  };

  const dropdownNames = allAsesments.map((item) => item.dropdownName);
  const uniqueDropdownNames = [...new Set(dropdownNames)];

  const fieldMapping = {
    'Tissue Viability': {
      firstTitle: 'Presence',
      firstField: 'tissueViabilityPresence',
      secondTitle: 'Percentage',
      secondField: 'tissueViabilityPercentage',
    },
    Infection: {
      firstTitle: 'Presence',
      firstField: 'infectionPresence',
      secondTitle: 'Observation',
      secondField: 'infectionObservation',
    },
    'Moisture Balance': {
      firstTitle: 'Exudate Level',
      firstField: 'moistureExudateLevel',
      secondTitle: 'Maceration',
      secondField: 'moistureMaceration',
      thirdTitle: 'Dihisced or Driedout Wounds',
      thirdField: 'moistureDriedoutWounds',
    },
    'Epithelial Edge': {
      firstTitle: 'Observation',
      firstField: 'epithelialEdge',
    },
    Periwound: {
      firstTitle: 'Observation',
      firstField: 'periwound',
    },
    'Regeneration and Repair': {
      firstTitle: 'Duration Of Wound',
      firstField: 'durationOfWound',
      secondTitle: 'Past Treatment',
      secondField: 'pastTreatment',
      thirdTitle: 'Proliferative Capacity Of The Cells',
      thirdField: 'proliferativeCapacityOfTheCells',
      fourthTitle: 'The Integrity Of The Stromal Framework',
      fourthField: 'theIntegrityOfTheStromalFramework',
      fifthTitle: 'Inflammatory Response',
      fifthField: 'inflammatoryResponse',
    },
  };

  const editTreatment = (treatment) => {
    const { _id, dropdownName, fields, treatments, advancedTherapies } =
      treatment;
    setSummaryId(_id);

    const mapping = fieldMapping[dropdownName];
    if (!mapping) {
      console.error(`No field mapping found for ${dropdownName}`);
      return;
    }
    const updatedFields = {};
    fields.forEach((fieldObject) => {
      Object.keys(fieldObject).forEach((key) => {
        if (key === mapping.firstField) {
          updatedFields.firstField = fieldObject[key];
        } else if (key === mapping.secondField) {
          updatedFields.secondField = fieldObject[key];
        } else if (key === mapping.thirdField) {
          updatedFields.thirdField = fieldObject[key];
        } else if (key === mapping.fourthField) {
          updatedFields.fourthField = fieldObject[key];
        } else if (key === mapping.fifthField) {
          updatedFields.fifthField = fieldObject[key];
        }
      });
    });
    setSelectedValues({
      firstField: updatedFields.firstField || '',
      secondField: updatedFields.secondField || '',
      thirdField: updatedFields.thirdField || '',
      fourthField: updatedFields.fourthField || '',
      fifthField: updatedFields.fifthField || '',
    });
    const treatmentOptions = treatments.map((treatment) => ({
      value: treatment.value,
      label: treatment.value,
    }));
    setTreatmentSuggestions(
      treatmentOptions.filter((treatment) =>
        treatmentOptions.some((opt) => opt.value === treatment.value)
      )
    );
    setAdvancedTherapies(advancedTherapies || '');
    setIsEdit(true);
    setOpen(true);
  };

  const handleSubmit = async () => {
    const activeFields = fieldMapping[activeTab];
    const selectedData = {};
    if (activeFields.firstField && selectedValues.firstField) {
      selectedData[activeFields.firstField] = selectedValues.firstField;
    }
    if (activeFields.secondField && selectedValues.secondField) {
      selectedData[activeFields.secondField] = selectedValues.secondField;
    }
    if (activeFields.thirdField && selectedValues.thirdField) {
      selectedData[activeFields.thirdField] = selectedValues.thirdField;
    }
    if (activeFields.fourthField && selectedValues.fourthField) {
      selectedData[activeFields.fourthField] = selectedValues.fourthField;
    }
    if (activeFields.fifthField && selectedValues.fifthField) {
      selectedData[activeFields.fifthField] = selectedValues.fifthField;
    }

    const data = {
      dropdownName: activeTab,
      fields: selectedData,
      treatments: treatmentSuggestions,
      advancedTherapies: advancedTherapies,
    };
    try {
      if (Object.keys(selectedData).length > 0) {
          if (isEdit) {
            await dispatch(editTreatments(summaryId, data));
          }
          if (!isEdit) {
            await dispatch(createTreatments(data));
          }
          dispatch(getAllTreatmentSuggestion({ dropdownName: activeTab }));
        } else {
          Toast.error('Internal server error');
      }
    } catch (error) {
      Toast.error('Internal server error');
    }
    handleClose();
  };

  const renderSelectFields = (activeTab) => {
    const fields = fieldMapping[activeTab];

    if (!fields || !activeTabData) {
      return null;
    }

    const mapOptionsForField = (fieldTitle) => {
      const fieldData = activeTabData.Values.find(
        (item) => item.category === fieldTitle
      );
      return (
        fieldData?.items?.map((item) => ({
          value: item.name,
          label: item.name,
        })) || []
      );
    };

    const firstFieldOptions = fields.firstTitle
      ? mapOptionsForField(fields.firstTitle)
      : [];
    const secondFieldOptions = fields.secondTitle
      ? mapOptionsForField(fields.secondTitle)
      : [];
    const thirdFieldOptions = fields.thirdTitle
      ? mapOptionsForField(fields.thirdTitle)
      : [];
    const fourthFieldOptions = fields.fourthTitle
      ? mapOptionsForField(fields.fourthTitle)
      : [];
    const fifthFieldOptions = fields.fifthTitle
      ? mapOptionsForField(fields.fifthTitle)
      : [];

    return (
      <div className='row'>
        {fields.firstField && firstFieldOptions.length > 0 && (
          <div className='col-6 mb-3'>
            <label htmlFor={`select-${fields.firstField}`}>
              {fields.firstTitle}
            </label>
            <Select
              id={`select-${fields.firstField}`}
              options={firstFieldOptions}
              placeholder='Select options'
              isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={firstFieldOptions.find(
                (option) => option.value === selectedValues?.firstField
              )}
              onChange={(selectedOption) =>
                handleSelectChange('firstField', selectedOption, false)
              }
              isDisabled={isEdit}
            />
          </div>
        )}

        {fields.secondField && secondFieldOptions.length > 0 && (
          <div className='col-6 mb-3'>
            <label htmlFor={`select-${fields.secondField}`}>
              {fields.secondTitle}
            </label>
            <Select
              id={`select-${fields.secondField}`}
              options={secondFieldOptions}
              placeholder='Select options'
              isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={secondFieldOptions.find(
                (option) => option.value === selectedValues?.secondField
              )}
              onChange={(selectedOption) =>
                handleSelectChange('secondField', selectedOption, false)
              }
              isDisabled={isEdit}
            />
          </div>
        )}

        {fields.thirdField && thirdFieldOptions.length > 0 && (
          <div className='col-6 mb-3'>
            <label htmlFor={`select-${fields.thirdField}`}>
              {fields.thirdTitle}
            </label>
            <Select
              id={`select-${fields.thirdField}`}
              options={thirdFieldOptions}
              placeholder='Select options'
              isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={thirdFieldOptions.find(
                (option) => option.value === selectedValues?.thirdField
              )}
              onChange={(selectedOption) =>
                handleSelectChange('thirdField', selectedOption, false)
              }
              isDisabled={isEdit}
            />
          </div>
        )}

        {fields.fourthField && fourthFieldOptions.length > 0 && (
          <div className='col-6 mb-3'>
            <label htmlFor={`select-${fields.fourthField}`}>
              {fields.fourthTitle}
            </label>
            <Select
              id={`select-${fields.fourthField}`}
              options={fourthFieldOptions}
              placeholder='Select options'
              isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={fourthFieldOptions.find(
                (option) => option.value === selectedValues?.fourthField
              )}
              onChange={(selectedOption) =>
                handleSelectChange('fourthField', selectedOption, false)
              }
              isDisabled={isEdit}
            />
          </div>
        )}

        {fields.fifthField && fifthFieldOptions.length > 0 && (
          <div className='col-6 mb-3'>
            <label htmlFor={`select-${fields.fifthField}`}>
              {fields.fifthTitle}
            </label>
            <Select
              id={`select-${fields.fifthField}`}
              options={fifthFieldOptions}
              placeholder='Select options'
              isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={fifthFieldOptions.find(
                (option) => option.value === selectedValues?.fifthField
              )}
              onChange={(selectedOption) =>
                handleSelectChange('fifthField', selectedOption, false)
              }
              isDisabled={isEdit}
            />
          </div>
        )}

        <div className='col-12 mb-3'>
          <label>Treatment Suggestion</label>
          <Select
            id='treatmentSuggestions'
            options={treatmentValues.map((treatment) => ({
              value: treatment.value,
              label: treatment.value,
            }))}
            value={treatmentSuggestions}
            placeholder='Select options'
            isClearable={true}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            isMulti
            components={animatedComponents}
            onChange={handleTreatmentChange}
          />
        </div>

        <div className='col-12 mb-3'>
          <label>Advanced Therapies</label>
          <TextField
            id='advancedTherapies'
            // label='Advanced Therapies'
            variant='outlined'
            fullWidth
            value={advancedTherapies}
            onChange={(e) =>
              handleTextFieldChange('advancedTherapies', e.target.value)
            }
            placeholder='Enter Advanced Therapies'
          />
        </div>
      </div>
    );
  };

  return (
    <PageLayout>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='col-12 col-md-12 col-sm-12 d-md-block'>
          <div className='row py-3'>
            <p className='heading'>Treatment Suggestion</p>
          </div>
          <div className='row' style={{ marginBlock: '2rem' }}>
            <div className='col-md-12'>
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                id='fill-tab-example'
                className='mb-3'
                fill='true'
              >
                {uniqueDropdownNames.map((dropdownName, index) => (
                  <Tab
                    sx={{ color: 'black' }}
                    label={<h5>{dropdownName}</h5>}
                    value={dropdownName}
                    key={index}
                  />
                ))}
              </Tabs>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 pe-5 d-flex justify-content-end'>
              <NewaddButton type='submit' onClick={addNewTreatment}>
                New Treatment
              </NewaddButton>
            </div>
            <div className='col-md-12'>
              <div className='container  p-3'>
                <div className='row d-flex flex-wrap'>
                  {allTreatmentSuggestion.map((treatment, index) => (
                    <div key={index} className='col-md-4 mb-5'>
                      <div
                        className='card shadow border border-1 position-relative  h-100'
                        style={{
                          borderTopRightRadius: '4rem',
                          borderBottomLeftRadius: '4rem',
                          backgroundImage:
                            'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
                        }}
                      >
                        <div className='card-body'>
                          <div
                            className='card-title mb-2 pb-2 border-bottom'
                            style={{ height: '50px' }}
                          >
                            <div className='text-center'>
                              <h5 className=' text-uppercase fw-weight-bold text-center'>
                                {treatment.dropdownName}
                              </h5>
                            </div>
                          </div>
                          {treatment.fields.map((value, valueIndex) => {
                            const {
                              firstTitle,
                              secondTitle,
                              thirdTitle,
                              fourthTitle,
                              fifthTitle,
                              firstField,
                              secondField,
                              thirdField,
                              fourthField,
                              fifthField,
                            } = fieldMapping[activeTab] || {};

                            const renderField = (fieldValue) => {
                              if (Array.isArray(fieldValue)) {
                                return fieldValue.map((item, itemIndex) => (
                                  <p key={itemIndex}>{item.value}</p>
                                ));
                              }
                              return <p>{fieldValue}</p>;
                            };

                            return (
                              <div key={valueIndex} className='border-bottom'>
                                <div className='row my-2'>
                                  {value[firstField] && (
                                    <div className='col-6'>
                                      <h6 className='text-uppercase fw-weight-bold text-left'>
                                        {firstTitle}
                                      </h6>
                                      {renderField(value[firstField])}
                                    </div>
                                  )}
                                  {value[secondField] && (
                                    <div className='col-6'>
                                      <h6 className='text-uppercase fw-weight-bold text-left'>
                                        {secondTitle}
                                      </h6>
                                      {renderField(value[secondField])}
                                    </div>
                                  )}
                                  {value[thirdField] && (
                                    <div className='col-6'>
                                      <h6 className='text-uppercase fw-weight-bold text-left'>
                                        {thirdTitle}
                                      </h6>
                                      {renderField(value[thirdField])}
                                    </div>
                                  )}
                                  {value[fourthField] && (
                                    <div className='col-6'>
                                      <h6 className='text-uppercase fw-weight-bold text-left'>
                                        {fourthTitle}
                                      </h6>
                                      {renderField(value[fourthField])}
                                    </div>
                                  )}
                                  {value[fifthField] && (
                                    <div className='col-6'>
                                      <h6 className='text-uppercase fw-weight-bold text-left'>
                                        {fifthTitle}
                                      </h6>
                                      {renderField(value[fifthField])}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          <h6 className='mt-2 text-uppercase fw-weight-bold text-center'>
                            Treatments
                          </h6>
                          <ul className='border-bottom'>
                            {treatment.treatments.map((value, valueIndex) => (
                              <li key={valueIndex} className='my-2'>
                                <p>{value.value}</p>
                              </li>
                            ))}
                          </ul>

                          {treatment.advancedTherapies && (
                            <>
                              <h6 className='mt-2 text-uppercase fw-weight-bold text-center'>
                                Advanced Therapies
                              </h6>
                              <div className='border-bottom'>
                                <div className='row my-2'>
                                  <div className='col-11'>
                                    <ul>
                                      <li>
                                        <p>{treatment.advancedTherapies}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className='row p-2'>
                          <div className='col-12 d-flex justify-content-around'>
                            <Button
                              variant='text'
                              className='col-md-6 add-btn'
                              onClick={() => editTreatment(treatment)}
                            >
                              <EditNoteIcon className='ms-1' />
                              Edit Treatments
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby='responsive-dialog-title'
              fullWidth
              maxWidth='md'
            >
              <div className='col-12 text-end' style={{ cursor: 'pointer' }}>
                <CloseIcon onClick={handleClose} />
              </div>
              <DialogTitle
                sx={{ textAlign: 'center' }}
                id='responsive-dialog-title'
              >
                <h4>Add New Treatment in {activeTab}</h4>
              </DialogTitle>
              <DialogContent
                sx={{
                  minHeight: '50vh',
                  overflowY: 'auto',
                }}
              >
                <FormControl fullWidth variant='standard'>
                  {renderSelectFields(activeTab)}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <div className='col-12 d-flex justify-content-center'>
                  <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </div>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Box>
    </PageLayout>
  );
};
export default TreatmentSuggestion;
