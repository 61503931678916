/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PageLayout from '../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  actionTreatmentCategory,
  getAllTreatment,
} from '../../Redux/TreatmentRedux';
import AlertBox from '../Common/AlertBox/AlertBox.js';

const TreatmentCategory = () => {
  const dispatch = useDispatch();
  const { allTreatment } = useSelector((state) => state.TreatmentManagement);
  const [addValue, setAddValue] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [valueId, setValueId] = useState('');
  const [EditValue, setEditValue] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllTreatment());
  }, []);

  const AddValue = (treatment) => {
    setAddValue('');
    setCategoryId(treatment._id);
    setAddOpen(true);
  };

  const editValue = (treatment, value) => {
    setCategoryId(treatment._id);
    setEditValue(value.value);
    setValueId(value._id);
    setEditOpen(true);
  };

  const addSubmit = async () => {
    try {
      const action = 'add';
      const data = {
        categoryId,
        newValue: addValue,
      };
      await new Promise((resolve) => {
        dispatch(actionTreatmentCategory(action, data));
        resolve();
      });
    } catch (error) {
      console.error('Error adding dropdown value:', error);
    }
  };

  const updateSubmit = async () => {
    try {
      const action = 'edit';
      const data = {
        categoryId,
        valueId: valueId,
        newValue: EditValue,
      };
      await new Promise((resolve) => {
        dispatch(actionTreatmentCategory(action, data));
        resolve();
      });
    } catch (error) {
      console.error('Error edit dropdown value:', error);
    }
  };

  return (
    <PageLayout>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='col-12 col-md-12 col-sm-12 d-md-block'>
          <div className='row py-3'>
            <p className='heading'>Treatment Category</p>
          </div>
          <div className='container  p-3'>
            <div className='row d-flex flex-wrap'>
              {allTreatment.map((treatment, index) => (
                <div key={index} className='col-md-4 mb-5'>
                  <div
                    className='card shadow border border-1 position-relative  h-100'
                    style={{
                      borderTopRightRadius: '4rem',
                      borderBottomLeftRadius: '4rem',
                      backgroundImage:
                        'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
                    }}
                  >
                    <div className='card-body'>
                      <div
                        className='card-title mb-2 pb-2 border-bottom'
                        style={{ height: '50px' }}
                      >
                        <div className='text-center'>
                          <h5 className=' text-uppercase fw-weight-bold text-center'>
                            {treatment.categoryName}
                          </h5>
                        </div>
                      </div>
                      {treatment.categoryValue.map((value, valueIndex) => (
                        <div key={valueIndex} className='border-bottom'>
                          <div className='row my-2'>
                            <div className='col-11'>
                              <p>{value.value}</p>
                            </div>
                            {/* <div className='col-1 d-flex justify-content-center'>
                              <EditIcon
                                className='text-primary fs-4'
                                onClick={() => editValue(treatment, value)}
                                style={{ cursor: 'pointer' }}
                              />
                            </div> */}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='row p-2'>
                      <div className='col-12 d-flex justify-content-around'>
                        <Button
                          variant='text'
                          className='col-md-6 add-btn'
                          onClick={() => AddValue(treatment)}
                        >
                          Add
                          <AddIcon className='ms-1 mb-1' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <AlertBox
                open={editOpen || addOpen}
                handleClose={() => {
                  setEditOpen(false);
                  setAddOpen(false);
                  // setDeleteOpen(false);
                  // setNewCardOpen(false);
                }}
                action={editOpen ? 'edit' : addOpen ? 'add' : ''}
                inputValue={editOpen ? EditValue : addValue}
                setInputValue={editOpen ? setEditValue : setAddValue}
                updateSubmit={updateSubmit}
                addSubmit={addSubmit}
                // deleteSubmit={deleteSubmit}
                // newCardSubmit={newCardSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </PageLayout>
  );
};

export default TreatmentCategory;
