/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from 'react-bootstrap/Container';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  AddButton,
  CancelButton,
  DeleteButton,
  EditButton,
  YesButton,
} from '../Tools/Buttons';
const actionConfig = {
  add: {
    title: 'Add Item',
    description: 'Do you want to add this item?',
    agreeText: 'Add',
    showTextField: true,
  },
  edit: {
    title: 'Edit Item',
    description: 'Do you want to edit this item?',
    agreeText: 'Edit',
    showTextField: true,
  },
  delete: {
    title: 'Delete Item',
    description: 'Are you sure you want to delete this item?',
    agreeText: 'Delete',
    showTextField: false,
  },
  logout: {
    title: 'Logout',
    description: 'Are you sure you want to logout?',
    agreeText: 'Yes',
    showTextField: false,
    showIcon: true,
  },
  newCard: {
    title: 'Add A New Card',
    description: 'Are you sure you want to add a new card?',
    agreeText: 'Yes',
    showTextField: false,
    showIcon: true,
  },
  saveDescription: {
    title: 'Save Description',
    description: 'Are you sure you want to Save this Description?',
    agreeText: 'Yes',
    showTextField: false,
  },
};

function AlertBox({
  open,
  handleClose,
  action,
  inputValue,
  setInputValue,
  updateSubmit,
  addSubmit,
  deleteSubmit,
  newCardSubmit,
  logoutSubmit,
  handleDelete,
  saveSubmit,
}) {
  const [edited, setEdited] = useState(false);

  const handleAction = async () => {
    try {
      if (action === 'delete') {
        await deleteSubmit();
        await handleDelete();
      } else if (action === 'edit') {
        await updateSubmit();
      } else if (action === 'add') {
        await addSubmit();
      } else if (action === 'newCard') {
        await newCardSubmit();
      } else if (action === 'logout') {
        await logoutSubmit();
      } else if (action === 'saveDescription') {
        await saveSubmit();
      }
      handleClose();
    } catch (error) {
      console.error('Error handling action:', error);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (action === 'edit') {
      setEdited(true);
    }
  };
  const { title, description, agreeText, showTextField, showIcon } =
    actionConfig[action] || {};

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: 'auto',
            width: '30%',
            overflow: 'hidden',
          },
        }}
      >
        <Container className='d-flex flex-column align-items-center justify-content-center'>
          {!showIcon && <h4 className='my-2'>{title}</h4>}
          {showIcon && (
            <ErrorOutlineIcon
              className='my-2'
              style={{ width: '20rem', height: '3rem', color: 'brown' }}
            />
          )}
          {!showTextField && <span className='my-3 fs-5'>{description}</span>}
          {showTextField && (
            <div className='d-flex align-items-center'>
              <label htmlFor='add-value-input' className='me-3'>
                {action === 'add' ? 'Add value' : 'Edit value'}
              </label>
              <TextField
                className='my-3'
                id='add-value-input'
                label={action === 'add' ? 'Add value:' : 'Edit value:'}
                variant='outlined'
                value={inputValue}
                onChange={handleInputChange}
                style={{ width: '300px' }}
                required
              />
            </div>
          )}
          <DialogActions>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            {action === 'edit' ? (
              <EditButton
                onClick={handleAction}
                disabled={action !== 'edit'}
                style={{}}
              >
                {agreeText}
              </EditButton>
            ) : null}
            {action === 'add' ? (
              <AddButton onClick={handleAction} disabled={action !== 'add'}>
                {agreeText}
              </AddButton>
            ) : null}
            {action === 'delete' ? (
              <DeleteButton onClick={handleAction}>{agreeText}</DeleteButton>
            ) : null}
            {action === 'newCard' ? (
              <YesButton onClick={handleAction}>{agreeText}</YesButton>
            ) : null}
            {action === 'logout' ? (
              <YesButton onClick={handleAction}>{agreeText}</YesButton>
            ) : null}
            {action === 'saveDescription' && (
              <YesButton onClick={handleAction}>{agreeText}</YesButton>
            )}
          </DialogActions>
        </Container>
      </Dialog>
    </>
  );
}

export default AlertBox;
