import React from 'react';
import PageLayout from '../Layout/Layout';
import { Box, Toolbar } from '@mui/material';

const DashboardPage = () => {
  return (
    <PageLayout>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <h1 className="heading">Dashboard</h1>
      </Box>
    </PageLayout>
  );
};

export default DashboardPage;
