/* eslint-disable no-useless-escape */
import TextField from '@mui/material/TextField';
import './StaffRegistration.css';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch } from 'react-redux';
import { fetchStaffs, staffRegistration } from '../../../Redux/StaffRedux';
import { localValue } from '../../../Redux/LocalRedux';
import Swal from 'sweetalert2';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CancelButton, SubmitButton } from '../../Common/Tools/Buttons';

export default function StaffRegistration() {
  const [staffName, setStaffName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('male');
  const [mobile, setMobile] = useState('');
  const [staffID, setStaffID] = useState('');
  const [dob, setDOB] = useState('');
  const [address, setAddress] = useState('');
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const handleClickOpen = (e) => {
    e.preventDefault();
    if (!validMobile(mobile)) {
      return;
    }
    if (!validEmail()) {
      return;
    }
    if (!validPassword()) {
      return;
    }
    if (!validBirthDate()) {
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const radioSelect = (event) => {
    setGender(event.target.value);
  };

  const user = JSON.parse(localStorage.getItem('user'));

  const ConfirmRegistration = () => {
    const data = {
      staffId: staffID,
      userName: staffName,
      DOB: dob,
      sex: gender,
      phoneNumber: mobile,
      password: password,
      emailId: email,
      address: address,
      createdBy: user.userName,
    };
    dispatch(staffRegistration(data))
      .then((response) => {
        if (response.message === 'Staff data stored successfully') {
          setOpen(false);
          dispatch(localValue(false));
          Swal.fire({
            icon: 'success',
            text: 'Staff registered successfully!',
            customClass: {
              popup: 'small-swal',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(fetchStaffs());
            }
          });
        }
      })
      .catch((error) => {
        setOpen(false);
        dispatch(localValue(false));
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Registration failed. Please try again.',
          customClass: {
            popup: 'small-swal',
          },
        });
      });
  };

  const validBirthDate = () => {
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!datePattern.test(dob)) {
      alert('Please enter the date in the format dd/mm/yyyy');
      return false;
    }
    return true;
  };
  const handleBirthDate = (e) => {
    const birthDate = e.target.value;
    setDOB(birthDate);
  };
  const validMobile = (mobile) => {
    const mobileNumber = /^[0-9]{10}$/;
    if (!mobile.match(mobileNumber)) {
      alert('Your mobile number is not valid!');
      return false;
    }
    return true;
  };

  const handleMobileChange = (e) => {
    let numericMobile = e.target.value.replace(/\D/g, '');
    setMobile(numericMobile);
  };
  const validEmail = () => {
    const emailReg =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!emailReg.test(email)) {
      alert('Please enter a valid email address');
      return false;
    }
    return true;
  };

  const handleToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validPassword = () => {
    const pwdRed = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z0-9]).{8,}$/;
    if (!pwdRed.test(password)) {
      alert('Please enter a valid Password');
      return false;
    }
    return true;
  };

  return (
    <div className="y content-container ">
      <div className="Registration-Form">
        <div className="col-md-12 p-2 text-center">
          <h4
            style={{
              backgroundColor: '#002366',
              color: '#fff',
              padding: '10px',
            }}
          >
            Add New Staff
          </h4>
        </div>
        <form onSubmit={handleClickOpen}>
          <div className="row d-flex justify-content-around mt-2">
            <TextField
              className="col-md-5"
              label="Staff-Id"
              variant="standard"
              onChange={(e) => setStaffID(e.target.value)}
              required
            />
            <TextField
              className="col-md-5"
              label="Staff-Name"
              variant="standard"
              onChange={(e) => setStaffName(e.target.value)}
              required
            />
          </div>
          <div className="row d-flex justify-content-around mt-2">
            <TextField
              className="col-md-5"
              label="DOB"
              variant="standard"
              onChange={handleBirthDate}
              placeholder="dd/mm/yyyy"
              required
            />
            <TextField
              className="col-md-5"
              label="Mobile number"
              variant="standard"
              inputProps={{ maxLength: 10 }}
              onChange={handleMobileChange}
              required
            />
          </div>
          <div className="row d-flex justify-content-around mt-2">
            <TextField
              className="col-md-5"
              label="Email-Id"
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              className="col-md-5"
              label="Password"
              variant="standard"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <span
                    className="items-center"
                    onClick={handleToggle}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </span>
                ),
              }}
            />
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-4">
            <FormLabel
              className="col-md-3"
              id="demo-row-radio-buttons-group-label"
            >
              Gender
            </FormLabel>
            <RadioGroup
              className="col-md-8 d-flex justify-content-between"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                className="col-md-4"
                value="male"
                control={<Radio />}
                label="Male"
                onChange={radioSelect}
                checked={gender === 'male'}
              />
              <FormControlLabel
                className="col-md-4"
                value="female"
                control={<Radio />}
                label="Female"
                onChange={radioSelect}
              />
            </RadioGroup>
          </div>

          <div className="row d-flex mt-3">
            <TextField
              id="outlined-multiline-static"
              label="Address"
              multiline
              rows={3}
              className="col-md-12"
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="row d-flex mt-4 justify-content-center">
            <SubmitButton type={'submit'}>Submit</SubmitButton>
          </div>
        </form>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {'Confirm this registration?'}
        </DialogTitle>

        <DialogActions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <button
            type="button"
            onClick={ConfirmRegistration}
            className="btn btn-outline-primary"
          >
            confirm
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
