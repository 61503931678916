import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { setEdit, dialogOpen } from '../../Redux/ProductRedux';

function HouseTable() {
  const dispatch = useDispatch();
  const { allHouseProducts } = useSelector((state) => state.ProductManagements);

  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = (id) => {
    dispatch(setEdit(id));
    dispatch(dialogOpen(true));
  };

  const columns = [
    {
      width: 50,
      renderCell: (params) => (
        <div className='row d-flex'>
          <div className='col-12'>
            <EditNoteIcon
              sx={{ color: '#1F75FE', cursor: 'pointer' }}
              onClick={() => {
                handleEdit(params.row.id);
              }}
            />
          </div>
        </div>
      ),
    },
    { field: 'productType', headerName: 'Product Type', width: 170 },
    { field: 'manufacturer', headerName: 'Manufacturer', width: 170 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'packSize', headerName: 'Pack Size', width: 100 },
    { field: 'code', headerName: 'Code', width: 100 },
    {
      field: 'suitableForType',
      headerName: 'Suitable For Type(1-8)',
      width: 130,
    },
    { field: 'availability', headerName: 'Availability', width: 100 },
    {
      field: 'manufacturersRecommendation',
      headerName: 'Manufacturers Recommendation In Brief',
      width: 300,
    },
    {
      field: 'levelOfAdoption',
      headerName: 'Level Of Adoption Scale(1-5)',
      width: 80,
    },
    { field: 'quantity', headerName: 'Quantity', width: 80 },
    { field: 'price', headerName: 'Price', width: 60 },
    { field: 'treatmentFor', headerName: 'Treatments', width: 500 },
  ];
  const rows = allHouseProducts.map((product, index) => {
    const suitableForType = product.suitableForType
      .map((item) => item.value)
      .join(', ');
    const treatmentFor = product.treatmentFor
      .map((item) => item.value)
      .join(', ');
    return {
      id: product._id,
      productType: product.productType,
      manufacturer: product.manufacturer,
      description: product.description,
      packSize: product.packSize,
      code: product.code,
      suitableForType: suitableForType,
      availability: product.availability,
      manufacturersRecommendation: product.manufacturersRecommendation,
      levelOfAdoption: product.levelOfAdoption,
      quantity: product.quantity,
      price: product.price,
      treatmentFor: treatmentFor,
    };
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: 'flex',
          borderBottom: '1px solid #E0E0E0',
          padding: '10px',
        }}>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }}/>
        <div style={{ flex: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  return (
    <div className='container'>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          pagination
          loading={isLoading}
        />
      </Box>
    </div>
  );
}

export default HouseTable;
