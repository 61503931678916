import Toast from '../components/Common/Toaster/Toaster';
export const FETCH_ASSESMENT_MANAGEMENT = 'FETCH_ASSESMENT_MANAGEMENT';
export const UPDATE_ASSESMENT_MANAGEMENT = 'UPDATE_ASSESMENT_MANAGEMENT';

const initialState = {
  allAsesments: [],
};
const AssesmentManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSESMENT_MANAGEMENT:
      return {
        ...state,
        allAsesments: action.payload,
      };

    default:
      return state;
  }
};
export default AssesmentManagementReducer;

export const fetchAssesmentDataSuccess = (data) => ({
  type: FETCH_ASSESMENT_MANAGEMENT,
  payload: data,
});

export const FetchAssesmentItems = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/assesment/get`,
        {
          method: 'GET',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const val = await response.json();
      dispatch(fetchAssesmentDataSuccess(val));
      return val;
    } catch (error) {}
  };
};
export const addDropdownValue = (addData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/assesment/add`,
        {
          method: 'POST',
          body: JSON.stringify({
            dropdownName: addData.dropdownName,
            category: addData.category,
            newValue: addData.name,
          }),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      //  dispatch(fetchDataSuccess(val));
      Toast.success('Added Successfully');
      return val;
    } catch (error) {}
  };
};
export const editDropdownValue = (edit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/assesment/edit/${edit.dropdownName}/${edit.id}`,
        {
          method: 'POST',
          body: JSON.stringify({
            category: edit.category,
            name: edit.name,
          }),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      //  dispatch(fetchDataSuccess(val));
      Toast.success('Edited Successfully');
      return val;
    } catch (error) {}
  };
};

export const deleteDropdownValue = (deleteItem) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/dropdown/assesment/delete/${deleteItem.dropdownName}/${deleteItem.id}`,
        {
          method: 'POST',
          body: JSON.stringify({ category: deleteItem.category }),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        Toast.error('Internal Server Error');
        throw new Error('Failed');
      }

      const val = await response.json();
      //  dispatch(fetchDataSuccess(val));
      Toast.success('Deleted Successfully');
      return val;
    } catch (error) {}
  };
};

export const SetDescriptionBox = (Id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/assesment/descriptionbox/${Id}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const val = await response.json();
      if (!val.category.descriptionBox) {
        Toast.error('Removed From Description');
      }
      if (val.category.descriptionBox) {
        Toast.success('Added To Description');
      }
      return val;
    } catch (error) {}
  };
};
