/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageLayout from '../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { FetchAssesmentItems } from '../../Redux/AssesmentManagementRedux';
import AssesmentData from './AssesmentDatas';
import { Box, Toolbar } from '@mui/material';

function AssesmentManagement() {
  const [activeTab, setActiveTab] = useState('Tissue Viability');
  const dispatch = useDispatch();
  const { allAsesments } = useSelector((state) => state.AssesmentItems);

  useEffect(() => {
    dispatch(FetchAssesmentItems());
  }, []);

  const dropdownNames = allAsesments.map((item) => item.dropdownName);
  const uniqueDropdownNames = [...new Set(dropdownNames)];

  return (
    <PageLayout>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='col-12 col-md-12 col-sm-12 d-md-block'>
          <div className='row py-3'>
            <p className='heading'>Assesment Management</p>
          </div>
          <div className='row' style={{ marginBlock: '2rem' }}>
            <div className='col-md-12'>
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                id='fill-tab-example'
                className='mb-3'
               fill="true"
              >
                {uniqueDropdownNames.map((dropdownName, index) => (
                  <Tab
                    sx={{ color: 'black' }}
                    label={<h5>{dropdownName}</h5>}
                    value={dropdownName}
                    key={index}
                  />
                ))}
              </Tabs>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <AssesmentData
                assesmentData={allAsesments}
                activeTab={activeTab}
              />
            </div>
          </div>
        </div>
      </Box>
    </PageLayout>
  );
}

export default AssesmentManagement;