import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

const pageLayout = ({ children }) => {
  return (
    <div>
      <Box sx={{ display: 'flex' }} className="holder">
        <CssBaseline />
        <Sidebar />
        {children}
      </Box>
    </div>
  );
};
export default pageLayout;
