/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PageLayout from '../Layout/Layout';
import { Box, Toolbar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DropDownDatas from './DropDownDatas';
import { useDispatch, useSelector } from 'react-redux';
import { FetchDropdowns } from '../../Redux/DataManagementRedux';

const DataManagement = () => {
  const [activeTab, setActiveTab] = useState('Patient Information');
  const dispatch = useDispatch();
  const { allDatas } = useSelector((state) => state.DropDowns);

  useEffect(() => {
    dispatch(FetchDropdowns());
  }, []);

  const uniquePageNames = Array.from(
    new Set(allDatas.map((item) => item.pageName))
  );

  return (
    <PageLayout>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className="col-12 col-md-12 col-sm-12 d-md-block">
          <div className="row py-3">
            <p className="heading">Data Management</p>
          </div>
          <div className="row" style={{ marginBlock: '2rem' }}>
            <div className="col-md-12">
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                id="fill-tab-example"
                className="mb-3"
                variant="fullWidth"
                aria-label="full width tabs example"
                fill="true"
              >
                {uniquePageNames.map((pageName, index) => (
                  <Tab
                    sx={{ color: 'black' }}
                    label={<h5>{pageName}</h5>}
                    value={pageName}
                    key={index}
                  />
                ))}
              </Tabs>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <DropDownDatas dropdown={allDatas} activeTab={activeTab} />
            </div>
          </div>
        </div>
      </Box>
    </PageLayout>
  );
};

export default DataManagement;
