/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Image } from "react-bootstrap";
import LoginScreenDoctor from "../../assets/images/LoginScreenDoctor.jpg";
import kromhLogo from "../../assets/images/KromhLogo.jpg";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from '../../Redux/ForgotPasswordRedux';

export default function ForgotPasswordPage() {
    const [showPassword, setShowPassword] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

   
    const ForgotPassword = () => {
        dispatch(forgotPassword(userName)).then(response => {
               });
      
    }


    return (
        <div className="y container-fluid p-0" style={{ overflow: 'hidden' }} >
            <div className="row m-0" >
                <div className="col-md-6 login-bg p-0" >
                    <Image
                        src={LoginScreenDoctor}
                        style={{ width: '90%', height: '100%', objectFit: 'cover' }}
                        fluid
                    />
                </div>

                <div className="col-md-6 content-container " >
                    <div className="row logo">
                        <Image style={{ width: '60%', height: '60%' }} src={kromhLogo} />
                        <div>
                            <h3 style={{ paddingTop: '10px' }}>Forgot Password</h3>
                            {/* <h6 style={{ marginTop: '10px' }}>You need to provide an access details before entering your system</h6> */}
                        </div>
                        <div>
                            <div><TextField  style={{ padding: "3px", marginTop: '10px', width: "250px" }} label="Username" variant="standard" onChange={(e) => setUserName(e.target.value)} />

                            </div>
                            <div>
                            <TextField
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ padding: "3px", marginTop: '20px', width: "250px" }}
                                label="New Password"
                                variant="standard"
                                
                            />
                    
                            </div>
                            <TextField
                                onChange={(e) => setPassword(e.target.value)}
                                style={{  marginTop: '20px' }}
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}

                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <div className="forgot" style={{ marginTop: '35px' }} >
                               
                                 <Button variant="contained" >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}