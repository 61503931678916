/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import {
  FetchAssesmentItems,
  editDropdownValue,
  addDropdownValue,
  deleteDropdownValue,
  SetDescriptionBox,
} from '../../Redux/AssesmentManagementRedux';
import AlertBox from '../Common/AlertBox/AlertBox';
import { FetchDescriptionDataCard } from '../../Redux/DescriptionManagementRedux';

function AssesmentDatas({ activeTab, assesmentData }) {
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [AddValue, setAddValue] = useState('');
  const [EditValue, setEditValue] = useState('');
  const [selectedID, setSelectedId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [selectedCheckbox, setSelectedCheckbox] = useState();

  const dispatch = useDispatch();
  const activeTabData = assesmentData.find(
    (item) => item.dropdownName === activeTab
  );

  const editValue = (category, value) => {
    setEditValue(value.name);
    setSelectedId(value._id);
    setSelectedCategory(category.category);
    setSelectedDropdown(activeTabData.dropdownName);
    setEditOpen(true);
  };

  const deleteValue = (category, value) => {
    setSelectedId(value._id);
    setSelectedCategory(category.category);
    setSelectedDropdown(activeTabData.dropdownName);
    setDeleteOpen(true);
  };

  const addValue = (category) => {
    setAddValue('');
    setSelectedDropdown(activeTabData.dropdownName);
    setSelectedCategory(category.category);
    setAddOpen(true);
  };

  const addSubmit = async () => {
    try {
      const addData = {
        dropdownName: selectedDropdown,
        category: selectedCategory,
        name: AddValue,
      };
      await dispatch(addDropdownValue(addData));
      await dispatch(FetchAssesmentItems());
      setAddOpen(false);
    } catch (error) {
      console.error('Error adding dropdown value:', error);
    }
  };

  const handleCheckBox = async (category) => {
    try {
      const Id = category._id;
      await dispatch(SetDescriptionBox(Id));
      await dispatch(FetchAssesmentItems());
      await dispatch(FetchDescriptionDataCard());
      setAddOpen(false);
    } catch (error) {
      console.error('Error adding dropdown value:', error);
    }
  };

  const updateSubmit = async () => {
    const edit = {
      id: selectedID,
      name: EditValue,
      category: selectedCategory,
      dropdownName: selectedDropdown,
    };
    await dispatch(editDropdownValue(edit));
    await dispatch(FetchAssesmentItems());
    setEditOpen(false);
  };

  const deleteSubmit = async () => {
    const deleteItem = {
      id: selectedID,
      category: selectedCategory,
      dropdownName: selectedDropdown,
    };
    await dispatch(deleteDropdownValue(deleteItem));
    await dispatch(FetchAssesmentItems());
    setDeleteOpen(false);
  };

  const dropdowns = activeTabData?.Values;
  let dropdownElements = null;

  if (dropdowns) {
    dropdownElements = dropdowns.map((category, index) => {
      return (
        <div
          key={index}
          className='col-md-4 mb-5'>
          <div
            className='card shadow border border-1 position-relative  h-100'
            style={{
              borderTopRightRadius: '4rem',
              borderBottomLeftRadius: '4rem',
              backgroundImage:
                'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
            }}>
            <div className='card-body'>
              <div
                className='card-title row d-flex mb-2 pb-2 border-bottom '
                style={{
                  height:
                    activeTab === 'Regeneration and Repair' ? '50px' : 'auto',
                }}>
                <div className='col-md-11 d-flex justify-content-center'>
                  <h5 className='text-uppercase fw-weight-bold text-center'>
                    {category.category}
                  </h5>
                </div>
                {/* <div className="col-md-1 d-flex justify-content-end align-items-start">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={category.descriptionBox}
                          onChange={() => handleCheckBox(category)}
                        />
                      }
                    />
                  </FormGroup>
                </div> */}
              </div>
              <div>
                {category.items.map((value, index) => (
                  <div
                    key={index}
                    className='border-bottom'>
                    <div className='row my-2'>
                      <div className='col-9'>
                        <p>{value.name}</p>
                      </div>
                      <div
                        className="col-1"
                        onClick={() => editValue(category, value)}
                        style={{ cursor: 'pointer' }}
                      >
                        <EditIcon className="text-primary fs-4" />
                      </div>
                      <div
                        className="col-1"
                        onClick={() => deleteValue(category, value)}
                        style={{ cursor: 'pointer' }}
                      >
                        <DeleteIcon className="text-danger fs-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='row p-2'>
              <div className='col-12 d-flex justify-content-around'>
                <Button
                  onClick={() => addValue(category)}
                  variant='text'
                  className='col-md-6 add-btn'>
                  Add
                  <AddIcon className='ms-1 mb-1' />
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <div className="container">
        <div className="row d-flex flex-wrap">{dropdownElements}</div>
      </div>
      <AlertBox
        open={editOpen || addOpen || deleteOpen}
        handleClose={() => {
          setEditOpen(false);
          setAddOpen(false);
          setDeleteOpen(false);
        }}
        action={
          editOpen ? 'edit' : addOpen ? 'add' : deleteOpen ? 'delete' : ''
        }
        inputValue={editOpen ? EditValue : AddValue}
        setInputValue={editOpen ? setEditValue : setAddValue}
        updateSubmit={updateSubmit}
        addSubmit={addSubmit}
        deleteSubmit={deleteSubmit}
      />
    </>
  );
}

export default AssesmentDatas;
