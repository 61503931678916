/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PageLayout from '../Layout/Layout';
import PatientTable from '../PatientInfo/PatientTable';
import PatientDetails from './PatientDetails';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients } from '../../Redux/PatientRedux';

const Patients = () => {
  const dispatch = useDispatch();
  const { viewDetails } = useSelector((state) => state.PatientDetails);

  useEffect(() => {
    dispatch(fetchPatients());
  }, []);

  return (
    <PageLayout>
      <Box
        component='main'
        sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='col-12 col-md-12 col-sm-12 d-md-block'>
          <div className='row py-3'>
            <p className='heading'>Patients Info</p>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {viewDetails ? <PatientDetails /> : <PatientTable />}
            </div>
          </div>
        </div>
      </Box>
    </PageLayout>
  );
};

export default Patients;
