export const LOGIN_USER = 'LOGIN_USER';

const initialState = {
  LoginUser: [],
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {

    case LOGIN_USER:
      return {
        ...state,
        LoginUser: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;

export const loginSuccess = (loginUser) => ({
  type: LOGIN_USER,
  payload: loginUser,
});

export const LoginUser = (data) => {
  return async (dispatch) => {
    const user = {
      grant_type: "password",
      username: data.username,
      password: data.password
    };
  
    const credentials = btoa(`${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`);

    const formData = new URLSearchParams();

    for (const key in user) {
      formData.append(key, user[key]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_api_url}/api/OAuth2/token`, {
        method: 'POST',
        body: formData.toString(),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic ${credentials}`
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
         console.error('Login failed:', errorData);
        return errorData
      } else {
        const responseData = await response.json();
        return responseData;
      }
    } catch (error) {
       console.error('Login error:', error);
      
    }
  };
};


