import React, { useState } from 'react';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useNavigate } from 'react-router-dom';
import AlertBox from '../Common/AlertBox/AlertBox';
import Profile from './Pofile';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useDispatch } from 'react-redux';
import { Popover } from '@mui/material';
import { FetchAdmin } from '../../Redux/StaffRedux';

function AvatarDropdown({ anchorEl, handleClose, setAvatar }) {
  const navigate = useNavigate();
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenProfile = () => {
    const emailId = localStorage.getItem('emailId');
    dispatch(FetchAdmin(emailId));
    setModelOpen(true);
  };

  const Logout = () => {
    navigate('/');
    window.sessionStorage.clear();
    window.localStorage.clear();
  };

  const handleLogout = () => {
    setLogoutOpen(true);
  };

  return (
    <>
      <Box>
        <FormControl fullWidth>
          <Popover
            aria-labelledby="demo-simple-select-label"
            // labelId='demo-simple-select-label'
            id="demo-simple-select"
            className="profile_popover"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: '3%',
                width: '10%',
                height: '25%',
                borderRadius: '10px',
              },
            }}
          >
            <h3 className="text-center p-2">Admin</h3>
            <div className="border border-bottom mb-2"></div>
            <MenuItem className="avatar_dropdown" onClick={handleOpenProfile}>
              <AccountCircleRoundedIcon className="me-3" />
              Profile
            </MenuItem>
            <MenuItem className="avatar_dropdown" onClick={handleLogout}>
              <LogoutRoundedIcon className="me-3" />
              Logout
            </MenuItem>
          </Popover>
        </FormControl>
      </Box>
      <AlertBox
        open={logoutOpen}
        handleClose={() => {
          setLogoutOpen(false);
        }}
        action="logout"
        logoutSubmit={Logout}
      />
      <Profile
        open={modelOpen}
        handleCloseProfile={() => {
          setModelOpen(false);
        }}
      />
    </>
  );
}

export default AvatarDropdown;
