import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  StyledTableCell,
  StyledTableRow,
} from '../StyledComponents/StyledComponents';
import Box from '@mui/material/Box';

import { useSelector, useDispatch } from 'react-redux';
import { staffEdit, deleteStaff } from '../../Redux/StaffRedux';
import AlertBox from '../Common/AlertBox/AlertBox';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
// function createData(staffID, name, emailID, phoneNumber) {
//     return { staffID, name, emailID, phoneNumber };
//   }

const StaffInfoTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [actionStatus, setActionStatus] = useState(false);
  const [name, setName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [number, setNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [isEditSave, setIsisEditSave] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setName(selectedStaff?.userName || '');
    setEmailId(selectedStaff?.emailId || '');
    setPassword(selectedStaff?.password || '');
    setNumber(selectedStaff?.phoneNumber || '');
    setDateOfBirth(selectedStaff?.DOB || '');
    setAddress(selectedStaff?.address || '');
  }, [selectedStaff]);

  useEffect(() => {
    const inputFields = [name, emailId, password, number, dateOfBirth, address];
    const selectedStaffFields = [
      selectedStaff?.userName,
      selectedStaff?.emailId,
      selectedStaff?.password,
      selectedStaff?.phoneNumber,
      selectedStaff?.DOB,
      selectedStaff?.address,
    ];
    const isEditSave = inputFields.some(
      (field, index) => field !== selectedStaffFields[index]
    );
    setIsisEditSave(isEditSave);
  }, [
    name,
    emailId,
    password,
    number,
    dateOfBirth,
    address,
    selectedStaff?.userName,
    selectedStaff?.emailId,
    selectedStaff?.password,
    selectedStaff?.phoneNumber,
    selectedStaff?.DOB,
    selectedStaff?.address,
  ]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { allStaffs } = useSelector((state) => state.staffDetails);
  const handleForm = (row) => {
    setSelectedStaff(row);
    setOpen(true);
    setName(row.userName || '');
    setEmailId(row.emailId || '');
    setPassword(row.password || '');
    setNumber(row.phoneNumber || '');
    setDateOfBirth(row.DOB || '');
    setAddress(row.address || '');
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (row) => {
    setSelectedRow(row);
    setDeleteOpen(true);
  };
  const deleteSubmit = () => {
    if (selectedRow && selectedRow.id) {
      dispatch(deleteStaff(selectedRow.id));
      setDeleteOpen(false);
    } else {
      console.error('No staff selected or staff ID is missing.');
    }
  };

  const handleSave = () => {
    const updatedStaff = {
      ...selectedStaff,
      userName: name,
      emailId: emailId,
      password: password,
      phoneNumber: number,
      DOB: dateOfBirth,
      address: address,
    };
    dispatch(staffEdit(selectedStaff._id, updatedStaff));
    setOpen(false);
  };

  const columns = [
    {
      field: 'staffId',
      headerName: 'Staff Id',
      flex: 1, // Adjusts width based on content
    },
    {
      field: 'userName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'emailId',
      headerName: 'Email Id',
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'DOB',
      headerName: 'DOB',
      flex: 1,
    },
    {
      field: 'sex',
      headerName: 'Gender',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div className='row d-flex justify-content-center'>
          <div className='col-md-3'>
            <VisibilityOutlinedIcon
              sx={{ color: '#0165FD', cursor: 'pointer' }}
              onClick={() => {
                handleForm(params.row);
                setActionStatus(true);
              }}
            />
          </div>
          <div className='col-md-3'>
            <EditIcon
              sx={{ color: '#023020', cursor: 'pointer' }}
              onClick={() => {
                handleForm(params.row);
                setActionStatus(false);
              }}
            />
          </div>
          <div className='col-md-3'>
            <DeleteIcon
              sx={{ color: '#FF0000', cursor: 'pointer' }}
              onClick={() => handleDelete(params.row)}
            />
          </div>
        </div>
      ),
    },
  ];

  const rows = allStaffs.map((staff, index) => {
    return {
      id: staff._id,
      staffId: staff.staffId,
      userName: staff.userName,
      password: staff.password,
      emailId: staff.emailId,
      phoneNumber: staff.phoneNumber,
      DOB: staff.DOB,
      sex: staff.sex,
      address: staff.address,
      createdBy: staff.createdBy,
    };
  });

  const dispatch = useDispatch();

  function CustomToolbar() {
    return (
      <GridToolbarContainer
      sx={{
        display: 'flex',
        borderBottom:"1px solid #E0E0E0",
        padding:'10px'
        }}
    >
      <Box>
        <GridToolbarExport />
      </Box>
      <div style={{ flex: 1 }} />
      <GridToolbarQuickFilter/>
    </GridToolbarContainer>
    );
  }

  return (
<Paper
    sx={{
      width: '100%',
    }}
    elevation={0}>
    <div className='container'>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[10, 20, 30]}
        pagination
      />
    </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle
          id='alert-dialog-title'
          className='row d-flex text-center'
          style={{
            backgroundColor: '#002366',
            color: '#fff',
            fontWeight: '500',
          }}>
          {selectedStaff && (
            <>
              <h3>{selectedStaff.userName}'S </h3>
              <p>Profile</p>
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div className='row my-5 mx-3'>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Staff Id'
                  value={selectedStaff?.staffId}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Name'
                  value={name}
                  InputProps={{
                    readOnly: actionStatus,
                  }}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className='row my-5 mx-3'>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Email Id'
                  value={emailId}
                  InputProps={{
                    readOnly: actionStatus,
                  }}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              </div>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Password'
                  value={password}
                  InputProps={{
                    readOnly: actionStatus,
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className='row my-5 mx-3'>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Number'
                  value={number}
                  InputProps={{
                    readOnly: actionStatus,
                  }}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Date Of Birth'
                  value={dateOfBirth}
                  InputProps={{
                    readOnly: actionStatus,
                  }}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>
            </div>
            <div className='row my-5 mx-3'>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Address'
                  value={address}
                  InputProps={{
                    readOnly: actionStatus,
                  }}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className='col-md-6'>
                <TextField
                  id='outlined-helperText'
                  label='Created By'
                  value={selectedStaff?.createdBy}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: '#002366',
            color: '#fff',
            fontWeight: '500',
          }}>
          {isEditSave && (
            <Button
              onClick={handleSave}
              variant='outlined'
              sx={{ color: '#fff' }}
              endIcon={<SaveIcon />}>
              Save
            </Button>
          )}
          <Button
            onClick={handleClose}
            variant='outlined'
            sx={{ color: '#fff' }}
            endIcon={<CloseIcon />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AlertBox
        open={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false);
        }}
        action={deleteOpen ? 'delete' : ''}
        deleteSubmit={deleteSubmit}
      />
    </Paper>
  );
};

export default StaffInfoTable;
