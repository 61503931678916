/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  FetchDropdowns,
  createDropdown,
  addDropdownValue,
  deleteDropdownValue,
  editDropdownValue,
} from '../../Redux/DataManagementRedux';
import AlertBox from '../Common/AlertBox/AlertBox';

function DropDownDatas({ activeTab, dropdown }) {
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newCardOpen, setNewCardOpen] = useState(false);
  const [addValue, setAddValue] = useState('');
  const [EditValue, setEditValue] = useState('');
  const [newCardValue, setNewCardValue] = useState('');
  const [selectedID, setSelectedId] = useState('');
  const [selectedValueId, setSelectedValueId] = useState('');
  const dispatch = useDispatch();

  const activeTabData = dropdown.filter((item) => item.pageName === activeTab);
  const dropdowns = activeTabData.map(({ dropdownName, values }) => ({
    dropdownName,
    values,
  }));

  const addNewCard = (value) => {
    setNewCardValue(value.name);
    setSelectedId(value._id);
    setNewCardOpen(true);
  };

  const editValue = (dropdown, value) => {
    setEditValue(value.name);
    setSelectedId(dropdown.dropdownName[0]._id);
    setSelectedValueId(value._id);
    setEditOpen(true);
  };
  const AddValue = (dropdown) => {
    setAddValue('');
    setSelectedId(dropdown.dropdownName[0]._id);
    setAddOpen(true);
  };
  const deleteValue = (dropdown, value) => {
    setSelectedValueId(value._id);
    setSelectedId(dropdown.dropdownName[0]._id);
    setDeleteOpen(true);
  };

  const addSubmit = async () => {
    try {
      const addData = {
        dropdownName: [{ _id: selectedID }],
        newValue: addValue,
      };
      await dispatch(addDropdownValue(addData));
      await dispatch(FetchDropdowns());
      setAddOpen(false);
    } catch (error) {
      console.error('Error adding dropdown value:', error);
    }
  };
  const newCardSubmit = async () => {
    try {
      const createData = {
        pageName: activeTabData[0].pageName,
        name: newCardValue,
        id: selectedID,
      };
      await dispatch(createDropdown(createData));
      await dispatch(FetchDropdowns());
      setNewCardOpen(false);
    } catch (error) {
      console.error('Error adding dropdown value:', error);
    }
  };

  const updateSubmit = async () => {
    const data = {
      dropdownId: selectedID,
      itemId: selectedValueId,
      value: EditValue,
    };
    await dispatch(editDropdownValue(data));
    await dispatch(FetchDropdowns());
    setEditOpen(false);
  };

  const deleteSubmit = async () => {
    try {
      const data = {
        dropdownId: selectedID,
        itemId: selectedValueId,
      };
      await dispatch(deleteDropdownValue(data));
      await dispatch(FetchDropdowns());
      setDeleteOpen(false);
    } catch (error) {
      console.error('Error deleting dropdown value:', error);
    }
  };

  return (
    <div className='container'>
      <div className='row d-flex flex-wrap'>
        {dropdowns.map((dropdown, index) => (
          <div
            key={index}
            className='col-md-4 mb-5'>
            <div
              className='card shadow border border-1 position-relative  h-100'
              style={{
                borderTopRightRadius: '4rem',
                borderBottomLeftRadius: '4rem',
                backgroundImage:
                  'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
              }}>
              <div className='card-body'>
                <div className='mb-2 pb-2 border-bottom'>
                  <div className='text-center'>
                    <h5 className='text-uppercase fw-weight-bold text-center'>
                      {dropdown.dropdownName.map((name) => name.name)}
                    </h5>
                  </div>
                </div>
                {dropdown.values.map((value, valueIndex) => (
                  <div
                    key={valueIndex}
                    className='border-bottom'>
                    <div className='row my-2'>
                      <div className='col-9'>
                        <p>{value.name}</p>
                      </div>
                      {/* {activeTabData[0].pageName === 'Wound Type' && (
                        <div className="col-1 d-flex justify-content-center">
                          <Tooltip title="Add new card data">
                            <AddIcon
                              className="text-success fs-4"
                              onClick={() => addNewCard(value)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </div>
                      )} */}

                      <div className="col-1 d-flex justify-content-center">
                        <EditIcon
                          className="text-primary fs-4"
                          onClick={() => editValue(dropdown, value)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="col-1 d-flex justify-content-center">
                        <RestoreFromTrashIcon
                          className="text-danger fs-4"
                          onClick={() => deleteValue(dropdown, value)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='row p-2'>
                <div className='col-12 d-flex justify-content-around'>
                  <Button
                    variant='text'
                    className='col-md-6 add-btn'
                    onClick={() => AddValue(dropdown)}>
                    Add
                    <AddIcon className='ms-1 mb-1' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <AlertBox
          open={editOpen || addOpen || deleteOpen || newCardOpen}
          handleClose={() => {
            setEditOpen(false);
            setAddOpen(false);
            setDeleteOpen(false);
            setNewCardOpen(false);
          }}
          action={
            editOpen
              ? 'edit'
              : addOpen
              ? 'add'
              : deleteOpen
              ? 'delete'
              : newCardOpen
              ? 'newCard'
              : ''
          }
          inputValue={editOpen ? EditValue : addValue}
          setInputValue={editOpen ? setEditValue : setAddValue}
          updateSubmit={updateSubmit}
          addSubmit={addSubmit}
          deleteSubmit={deleteSubmit}
          newCardSubmit={newCardSubmit}
        />
      </div>
    </div>
  );
}
export default DropDownDatas;
