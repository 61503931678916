
export const VALUE = "/VALUE1";

 
export const localValue = (payload) => {

  return {
    type: VALUE,
    payload,
  };
};


const initialState = {
  value: "",
};
const Auth = (state = initialState, action) => {


  switch (action.type) {
    case VALUE:
      return {
        ...state,
        value: action.payload,      // to stored a single object 
        // value: [...state.value,action.payload],  // to stored a multiple object in an array
      };

    default:
      return state;
  }
};

export default Auth;