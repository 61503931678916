/* eslint-disable no-unused-vars */
import Toast from '../components/Common/Toaster/Toaster';
export const STAFF_REGISTRATION = 'STAFF_REGISTRATION';
export const FETCH_STAFFS_SUCCESS = 'FETCH_STAFFS_SUCCESS';
// export const FETCH_ONE_STAFF_SUCCESS = 'FETCH_ONE_STAFF_SUCCESS';
export const EDIT_ONE_STAFF_SUCCESS = 'EDIT_ONE_STAFF_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

const initialState = {
  StaffDetail: [],
  allStaffs: [],
  // oneStaff: [],
  userDetail: [],
};

const StaffRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAFF_REGISTRATION:
      return {
        ...state,
        StaffDetail: action.payload,
      };
    case FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        allStaffs: action.payload,
      };
    // case FETCH_ONE_STAFF_SUCCESS:
    //   return {
    //     ...state,
    //     oneStaff: action.payload,
    //   };
    case EDIT_ONE_STAFF_SUCCESS:
      const editedIndex = state.allStaffs.findIndex(
        (staff) => staff.staffId === action.payload.staffId
      );
      const updatedAllStaffs = [
        ...state.allStaffs.slice(0, editedIndex),
        action.payload,
        ...state.allStaffs.slice(editedIndex + 1),
      ];
      return {
        ...state,
        allStaffs: updatedAllStaffs,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      };
    default:
      return state;
  }
};

export default StaffRegistrationReducer;

export const newStaff = (staff) => ({
  type: STAFF_REGISTRATION,
  payload: staff,
});

export const fetchAllStaff = (staff) => ({
  type: FETCH_STAFFS_SUCCESS,
  payload: staff,
});

// export const fetchOne = (staff) => ({
//   type: FETCH_ONE_STAFF_SUCCESS,
//   payload: staff,
// });
export const editOneStaff = (staff) => ({
  type: EDIT_ONE_STAFF_SUCCESS,
  payload: staff,
});
export const fetchUserDetails = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const staffRegistration = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/newstaff/create`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Failed to create user';
        throw new Error(errorMessage);
      }
      const val = await response.json();
      dispatch(newStaff(val));
      return val;
    } catch (error) {
      console.error('Error in staff registration:', error);

      throw error;
    }
  };
};
export const staffEdit = (staffId, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/newstaff/create/edit/${staffId}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        Toast.error('Internal Server Error');
        const errorMessage = errorData.message || 'Failed to edit user';
        throw new Error(errorMessage);
      }

      const val = await response.json();
      Toast.success('Edited Successfully');
      dispatch(editOneStaff(val));
      return val;
    } catch (error) {
      console.error('Error in staff edit:', error);
      throw error;
    }
  };
};
export const deleteStaff = (staffId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/newstaff/create/delete/${staffId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorMessage = `Failed to delete user: ${response.statusText}`;
        console.error(errorMessage);
        Toast.error('Internal Server Error');
        return;
      }

      Toast.success('Deleted Successfully');

      dispatch(fetchStaffs());
    } catch (error) {
      console.error('Error in deleting staff:', error);
    }
  };
};

export const fetchStaffs = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/getall/staffs`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to get user');
      }
      const val = await response.json();
      dispatch(fetchAllStaff(val));
    } catch (error) {}
  };
};

export const fetchOneStaff = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/getall/staffs/${id}`,
        {
          //here only declare the database name and this is the endpoint
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to get user');
      }
      const val = await response.json();
      // dispatch(fetchOne(val));
      dispatch(fetchAllStaff(val));
    } catch (error) {}
  };
};

export const FetchAdmin = (emailId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/getall/admins/${emailId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        const errorMessage = 'Failed to authenticated';
        throw new Error(errorMessage);
      }

      const val = await response.json();
      const userEmail = val[0]?.emailId;
      localStorage.setItem('emailId', userEmail);
      const valemail = localStorage.getItem('emailId');
      dispatch(fetchUserDetails(val));
    } catch (error) {
      console.error('Error in FetchAdmin  :', error);
      throw error;
    }
  };
};
