/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import Toast from '../components/Common/Toaster/Toaster';
export const FETCH_ALL_PATIENTS = 'FETCH_ALL_PATIENTS';
export const SET_VIEW_DETAILS = 'SET_VIEW_DETAILS';
export const SET_PDF_BLOB = 'SET_PDF_BLOB';

const initialState = {
  allPatients: [],
  pdfBlob: null,
  viewDetails: false,
};

const PatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PATIENTS:
      return {
        ...state,
        allPatients: action.payload,
      };
    case SET_VIEW_DETAILS:
      return {
        ...state,
        viewDetails: action.payload,
      };
      case SET_PDF_BLOB:
        return {
          ...state,
          pdfBlob: action.payload,
        };
    default:
      return state;
  }
};

export default PatientReducer;

export const fetchAllPatients = (patients) => ({
  type: FETCH_ALL_PATIENTS,
  payload: patients,
});

export const setPdfBlob = (blob) => ({
  type: SET_PDF_BLOB,
  payload: blob,
});

export const setViewDetails = (viewDetails) => ({
  type: SET_VIEW_DETAILS,
  payload: viewDetails,
});

export const fetchPatients = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/get/patient/info`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to get patients');
      }
      const val = await response.json();
      dispatch(fetchAllPatients(val));
    } catch (error) {}
  };
};

export const fetchPdfUrl = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/download/patient/info/${data.id}/${data.visit}`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        Toast.error('Failed to get patient Details');
        throw new Error('Failed to get patient details');
      }
      const blob = await response.blob();
      dispatch(setPdfBlob(blob));
      dispatch(setViewDetails(true));
    } catch (error) {
      Toast.error('Failed to get patient details');
    }
  };
};