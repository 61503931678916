import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  CreateDescriptionData,
  FetchDescriptionDataCard,
} from '../../Redux/DescriptionManagementRedux';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup } from '@mui/material';
import { SaveButton } from '../Common/Tools/Buttons';

function DescriptionData({ selectedValue }) {
  const { dataCard } = useSelector((state) => state.DescriptionManagement);
  const selectedName = localStorage.getItem('selectedName');
  const selectedDescptId = localStorage.getItem('selectedDescptId');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [radioSelections, setRadioSelections] = useState([]);
  const [checkboxSelections, setCheckboxSelections] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchDescriptionDataCard());
  }, [dispatch]);

  const handleRadioChange = (category, itemName, itemId) => {
    setRadioSelections((prevSelections) => {
      const existingSelectionIndex = prevSelections.findIndex(
        (selection) =>
          selection.dropdownName === category.dropdownName &&
          selection.category === category.category
      );
      if (existingSelectionIndex !== -1) {
        const updatedSelections = [...prevSelections];
        updatedSelections[existingSelectionIndex].value = [
          { name: itemName, _id: itemId },
        ];
        return updatedSelections;
      } else {
        return [
          ...prevSelections,
          {
            dropdownName: category.dropdownName,
            category: category.category,
            value: [{ name: itemName, _id: itemId }],
          },
        ];
      }
    });
  };

  const handleCheckBoxChange = (category, item) => (itemId) => (event) => {
    const isChecked = event.target.checked;
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [itemId]: isChecked,
      };
      return updatedCheckedItems;
    });
    const index = checkboxSelections.findIndex(
      (item) => item.dropdownName === category.dropdownName
    );
    const updatedSelections = [...checkboxSelections];
    if (index !== -1) {
      if (isChecked) {
        updatedSelections[index].value.push({ name: item.name, _id: itemId });
      } else {
        updatedSelections[index].value = updatedSelections[index].value.filter(
          (selection) => selection._id !== itemId
        );
      }
    } else {
      updatedSelections.push({
        dropdownName: category.dropdownName,
        category: category.category,
        value: isChecked ? [{ name: item.name, _id: itemId }] : [],
      });
    }
    setCheckboxSelections(updatedSelections);
  };

  const handleSaveClick = async () => {
    const lines = descriptionValue
      .split('\n')
      .filter((line) => line.trim() !== '');
    const descriptionTextArray = lines.map((text) => ({
      text: text.trim(),
    }));
    let newData = {
      woundType: { name: selectedName, _id: selectedDescptId },
      woundSubCategory: { name: selectedValue.name, _id: selectedValue.id },
      cardData: [...radioSelections, ...checkboxSelections],
      descriptionText: descriptionTextArray,
    };
    dispatch(CreateDescriptionData(newData));
  };

  return (
    <div className="container">
      <div className="row d-flex flex-wrap align-items-stretch">
        {dataCard.map((category) => (
          <div
            className="col-md-4 mb-5 d-flex position-relative"
            key={category._id}
          >
            <div
              className="card shadow border border-1 position-relative  h-100 w-100 my-3"
              style={{
                borderTopRightRadius: '4rem',
                borderBottomLeftRadius: '4rem',
                backgroundImage:
                  'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
              }}
            >
              <div className="card-body">
                <div className="mb-2 pb-2 border-bottom border-secondary">
                  <div className="text-center">
                    <h5 className="text-uppercase fw-weight-bold text-center">
                      {category.dropdownName.toUpperCase()}
                    </h5>
                  </div>
                  <div className="text-center">
                    <h6 className="text-uppercase fw-weight-bold text-center">
                      ({category.category.toUpperCase()})
                    </h6>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="row my-2 d-flex align-items-center">
                    <div className="col-12 d-flex justify-content-evenly">
                      <FormControl component="fieldset">
                        {category.dropdownName === 'Epithelial Edge' ||
                        category.dropdownName === 'Periwound' ? (
                          <FormGroup>
                            {category.items.map((item) => (
                              <FormControlLabel
                                key={item._id}
                                control={
                                  <Checkbox
                                    checked={!!checkedItems[item._id]}
                                    onChange={handleCheckBoxChange(
                                      category,
                                      item
                                    )(item._id)}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </FormGroup>
                        ) : (
                          <RadioGroup
                            row
                            aria-labelledby={`demo-row-radio-buttons-group-label-${category.category}`}
                            name={`row-radio-buttons-group-${category.category}`}
                          >
                            {category.items.map((item) => (
                              <FormControlLabel
                                key={item._id}
                                control={<Radio />}
                                label={item.name}
                                value={item.name}
                                id={item._id}
                                onChange={(e) => {
                                  handleRadioChange(
                                    category,
                                    e.target.value,
                                    item._id
                                  );
                                }}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="col-md-12 my-2">
        <div
          className="card shadow border border-1 position-relative  h-100"
          style={{
            borderTopRightRadius: '4rem',
            borderBottomLeftRadius: '4rem',
            backgroundImage:
              'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
          }}
        >
          <div className="card-body">
            <div className="mb-2 pb-2 border-bottom">
              <div className="text-center">
                <h5 className="text-uppercase fw-weight-bold text-center">
                  Description
                </h5>
              </div>
            </div>
            <div className="row my-1 d-flex align-items-center">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1"></label>
                <textarea
                  className="form-control"
                  id="descriptionTextArea"
                  rows="10"
                  value={descriptionValue}
                  onChange={(e) => setDescriptionValue(e.target.value)}
                ></textarea>
              </div>
              <div className="row p-3 d-flex justify-content-evenly">
                <div className="col-md-3">
                  <SaveButton
                    onClick={handleSaveClick}
                    disabled={descriptionValue === ''}
                  >
                    Save Description
                  </SaveButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DescriptionData;
