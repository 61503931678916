import Toast from '../components/Common/Toaster/Toaster';
export const FETCH_DATA_CARD = 'FETCH_DATA_CARD';
export const FETCH_CATEGORY_DATA = 'FETCH_CATEGORY_DATA';
export const FETCH_ONE_CATEGORY_DATA = 'FETCH_ONE_CATEGORY_DATA';
export const FETCH_SELECTED_DESCRIPTION = 'FETCH_SELECTED_DESCRIPTION';
export const DESCRIPTION_NOT_FOUND = 'DESCRIPTION_NOT_FOUND';
export const RESET_DESCRIPTION_NOT_FOUND = 'RESET_DESCRIPTION_NOT_FOUND';
export const CREATED_DESCRIPTION = 'CREATED_DESCRIPTION';

const initialState = {
  dataCard: [],
  categoryData: [],
  oneCategoryData: [],
  descriptionData: [],
  descriptionNotFound: false,
  descriptionNewData: [],
};

const DescriptionManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_CARD:
      return {
        ...state,
        dataCard: action.payload,
      };
    case FETCH_CATEGORY_DATA:
      return {
        ...state,
        categoryData: action.payload,
      };
    case FETCH_ONE_CATEGORY_DATA:
      return {
        ...state,
        oneCategoryData: action.payload,
      };
    case FETCH_SELECTED_DESCRIPTION:
      return {
        ...state,
        descriptionData: action.payload,
        descriptionNotFound: false,
      };
    case DESCRIPTION_NOT_FOUND:
      return {
        ...state,
        descriptionNotFound: true,
        descriptionData: '',
      };
    case RESET_DESCRIPTION_NOT_FOUND:
      return {
        ...state,
        descriptionNotFound: false,
        descriptionData: '',
      };
    case CREATED_DESCRIPTION:
      return {
        ...state,
        descriptionNewData: action.payload,
        descriptionNotFound: false,
      };
    default:
      return state;
  }
};
export default DescriptionManagementReducer;

export const fetchDataCard = (data) => ({
  type: FETCH_DATA_CARD,
  payload: data,
});
export const fetchCategoryData = (data) => ({
  type: FETCH_CATEGORY_DATA,
  payload: data,
});
export const fetchOneCategoryData = (data) => ({
  type: FETCH_ONE_CATEGORY_DATA,
  payload: data,
});
export const fetchedDescription = (data) => ({
  type: FETCH_SELECTED_DESCRIPTION,
  payload: data,
});

export const descriptionNotFound = () => ({
  type: DESCRIPTION_NOT_FOUND,
});

export const resetDescriptionNotFound = () => ({
  type: RESET_DESCRIPTION_NOT_FOUND,
});

export const createdDescription = () => ({
  type: RESET_DESCRIPTION_NOT_FOUND,
});

export const FetchDescriptionDataCard = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/description/datacard/get`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        // Toast.error('Internal Server Error');
      }
      const res = await response.json();
      dispatch(fetchDataCard(res.categories));
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};

export const FetchDescriptionCategoryData = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/description/get/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        // Toast.error('Internal Server Error');
      }
      const res = await response.json();
      dispatch(fetchCategoryData(res.descriptions));
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};

export const FetchOneDescriptionCategoryData = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/description/get/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        // Toast.error('Internal Server Error');
      }
      const res = await response.json();
      dispatch(fetchOneCategoryData(res.descriptions));
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};

export const CreateDescriptionData = (newData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/description/create/new`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newData),
        }
      );

      if (!response.ok) {
        throw new Error('Internal Server Error');
      }

      const res = await response.json();
      if (
        response.status === 400 &&
        res.message === 'Description already exists'
      ) {
        Toast.error(res.message);
      } else {
        // dispatch(createdDescription(res));
        Toast.success('New Description Created');
      }
      return res;
    } catch (error) {
      console.error(error);
      Toast.error('Description not created');
      // Toast.error(error.message);
    }
  };
};

export const EditDeleteDescriptionData = (data) => {
  const action = data.action;
  const id = data.id;
  const descriptionText = data.descriptionText;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_api_url}/api/description/${action}/${id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ descriptionText }),
        }
      );
      if (!response.ok) {
        // Toast.error('Internal Server Error');
        throw new Error('Internal Server Error');
      }
      const res = await response.json();
      Toast.success(res.message);
      return res;
    } catch (error) {
      console.error(error);
      Toast.error('Description not created');
      // Toast.error(error.message);
    }
  };
};



