import React, { useEffect, useState } from 'react';
import PageLayout from '../Layout/Layout';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FetchSelectedData } from '../../Redux/DataManagementRedux';
import { Box, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CreateButton, ViewButton } from '../Common/Tools/Buttons';
import DescriptionData from './DescriptionCreateData';
import { FetchDescriptionCategoryData } from '../../Redux/DescriptionManagementRedux';
import DescriptionCard from './DescriptionCard';

function DescriptionManagement() {
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => state.DropDowns);
  const selectedDescptId = localStorage.getItem('selectedDescptId');
  const selectedName = localStorage.getItem('selectedName');
  const [selectedValue, setSelectedValue] = useState('');
  const [isCreateDescriptionVisible, setCreateDescriptionVisible] =
    useState(true);

  const tab =
    selectedData[0]?.values.map((item) => ({
      id: item._id,
      name: item.name,
    })) || [];

  useEffect(() => {
    let id = selectedDescptId;
    dispatch(FetchSelectedData(id));
    dispatch(FetchDescriptionCategoryData(id));
  }, [selectedDescptId, isCreateDescriptionVisible, dispatch]);

  return (
    <PageLayout>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12 d-md-block">
            <div
              className="row d-flex align-items-center"
              style={{ marginBlock: '2rem' }}
            >
              <div className="col-md-12 d-flex">
                <p className="heading">{selectedName}</p>
              </div>
            </div>
            <div className="row d-flex align-items-center my-3">
              <div className="col-md-6  d-flex">
                <FormControl
                  sx={{
                    m: 1,
                    width: '100%',
                  }}
                >
                  {!isCreateDescriptionVisible && (
                    <Select
                      value={selectedValue ? selectedValue.id : ''}
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        const selectedItem = tab.find(
                          (item) => item.id === selectedId
                        );
                        setSelectedValue(selectedItem || '');
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {tab.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {isCreateDescriptionVisible && <></>}
                </FormControl>
              </div>
              <div className="col-md-5 d-flex justify-content-end">
                {isCreateDescriptionVisible && (
                  <CreateButton
                    onClick={() => setCreateDescriptionVisible(false)}
                  >
                    Create Description
                  </CreateButton>
                )}
                {!isCreateDescriptionVisible && (
                  <ViewButton onClick={() => setCreateDescriptionVisible(true)}>
                    View Descriptions
                  </ViewButton>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {isCreateDescriptionVisible && (
                  <DescriptionCard selectedValue={selectedValue} />
                )}
                {!isCreateDescriptionVisible && (
                  <DescriptionData selectedValue={selectedValue} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </PageLayout>
  );
}

export default DescriptionManagement;
